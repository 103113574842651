import { useQuery } from '@apollo/client';
import { JSX, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { QUERY_GET_ADMIN_SITE_DETAILS } from '../../../services/queries';
import { AdminDetailsMainSectionWrapper } from '../../2-templates';
import { ErrorPage, PageNotFoundErrorPage } from '../error-page';
import { LoadingPage } from '../loading-page';
import { generateDeviceRows, siteDevicesDataGridColumns } from './data-grid-configurations';
import { SiteDetailsAdminPanel } from './site-details-admin-panel';
import { AdminAddActionButton, AdminDetailsDataGrid } from '../../4-features';
import { UserTimezoneContext } from '../../contexts';

export const SiteDetailsAdminPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { siteId } = useParams();
  const { userTimezone } = useContext(UserTimezoneContext);
  const { data, loading, error } = useQuery(QUERY_GET_ADMIN_SITE_DETAILS, { variables: { siteId: siteId! } });

  if (error) {
    const isUUIDError = error.name === 'ApolloError' && error.message.includes('invalid input syntax for type uuid');

    if (isUUIDError) {
      return <PageNotFoundErrorPage />;
    }

    return (
      <ErrorPage
        titleEmphasized={t('apolloErrorPage.errorCode')}
        title={t('apolloErrorPage.errorTitle')}
        message={error.message}
      />
    );
  }

  if (loading) {
    return <LoadingPage />;
  }

  const siteDetails = data?.siteByPK;
  if (!siteDetails) {
    return <PageNotFoundErrorPage />;
  }

  return (
    <AdminDetailsMainSectionWrapper panel={<SiteDetailsAdminPanel siteDetails={siteDetails} />}>
      <div className="site-details-admin-page" data-testid="site-details-admin-page">
        <div className="site-details-admin-page__devices" data-testid="site-details-admin-page-devices">
          <div className="site-details-admin-page__title-bar">
            <h2>{t('siteAdminDetailsPage.deviceDataGrid.title')}</h2>
            <AdminAddActionButton
              titleName={t('siteAdminDetailsPage.deviceDataGrid.add')}
              disabled={true}
              data-testid="site-details-admin-page-add-device"
            />
          </div>
          <div className="site-details-admin-page__data-grid">
            <AdminDetailsDataGrid
              columns={siteDevicesDataGridColumns}
              loading={loading}
              rows={generateDeviceRows(siteDetails.devices, userTimezone)}
              data-testid="site-details-admin-page-devices-data-grid"
            />
          </div>
        </div>
      </div>
    </AdminDetailsMainSectionWrapper>
  );
};

import { gql } from '../../../../__generated__/gql';

export const QUERY_GET_COMPANY_NAMES = gql(`
  query getCompanyNames($filters: CompanyBoolExp) {
    companies(orderBy: { name: ASC }, where: $filters) {
      id
      name
      companyType
    }
  }
`);

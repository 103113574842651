import { gql } from '../../../../__generated__/gql';

export const QUERY_GET_ADMIN_DEVICE_DETAILS = gql(`
  query getAdminDeviceDetails($deviceId: uuid!) {
    deviceByPK(id: $deviceId) {
      id
      deactivated
      serialNumber
      program {
        name
      }
      site {
        location {
          id
          latitude
          longitude
        }
        customer {
          companyId
          company {
            id
            name
          }
        }
        id
        name
      }
      deviceType {
        id
        name
        modelNumber
      }
      connectorHolderType {
        id
        connectorType
        name
      }
      createdAt
      updatedAt
      location {
        id
        latitude
        longitude
      }
      description
      deviceGroupDevices(orderBy: {deviceGroup: {name: ASC}}) {
        createdAt
        updatedAt
        deviceGroup {
          id
          name
          serviceProvider {
            companyId
            company {
              id
              name
            }
          }
          deviceGroupDevicesAggregate {
            aggregate {
              count
            }
          }
          userDeviceGroupsAggregate {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`);

import classNames from 'classnames';
import { includes } from 'lodash';
import { JSX, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { DataTestId } from '../../../../types';
import { RSButton, RSButtonProps } from '../../../5-elements';
import { RSNavLinkTimezone, RSNavLinkTimezoneProps } from '../../rs-navlink-timezone';

type AdminNavMenuName = 'companies' | 'users' | 'devices';
export interface AdminNavMenuButtonProps extends DataTestId {
  name: AdminNavMenuName;
  buttonLabel: ReactNode;
  navLinkProps: RSNavLinkTimezoneProps;
  buttonProps?: RSButtonProps;
}

export const AdminNavMenuButton = ({
  name,
  'data-testid': dataTestId,
  buttonLabel,
  navLinkProps,
  buttonProps
}: AdminNavMenuButtonProps): JSX.Element => {
  const routerLocation = useLocation();
  const menuButtonClassNames = (isActive: boolean, name: AdminNavMenuName) => {
    // Special case: Make "Companies" active if the pathname matches `/portal/admin/sites` or
    // `/portal/admin/device-groups`
    const linkActive =
      isActive === true ||
      (name === 'companies' &&
        (includes(routerLocation.pathname, '/portal/admin/sites/') ||
          includes(routerLocation.pathname, '/portal/admin/device-groups/')));
    return classNames('admin-nav-menu-button', { 'admin-nav-menu-button--active': linkActive === true });
  };

  return (
    <RSNavLinkTimezone
      {...navLinkProps}
      className={({ isActive }) => menuButtonClassNames(isActive, name)}
      data-testid={dataTestId}
    >
      <RSButton {...buttonProps} extraClassNames={['admin-nav-menu-button__button']} color="success">
        {buttonLabel}
      </RSButton>
    </RSNavLinkTimezone>
  );
};

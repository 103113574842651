import { ApolloQueryResult, useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dispatch, JSX, SetStateAction, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AddSiteRequest, addSiteSchema } from './validation-schema';
import { CreateSiteMutationVariables, GetAdminCompanyDetailsQuery } from '../../../../__generated__/graphql';
import { appConfig, graphqlApiConfig } from '../../../../configs';
import { MUTATION_CREATE_SITE } from '../../../../services/mutations';
import { browserSupportedTimezones } from '../../../../utilities';
import { RSDrawer, RSDrawerProps } from '../../../3-sections';
import { ConfirmationModal, DrawerButtonsGroup, ModalDrawerHeader } from '../../../4-features';
import { RSSelect, RSSelectItemProps, RSTextInput } from '../../../5-elements';
import { useEnqueueSnackbar } from '../../../hooks';

interface AddSiteDrawerProps extends Omit<RSDrawerProps, 'children'> {
  setOpenAddSite: Dispatch<SetStateAction<boolean>>;
  refetchCompany: () => Promise<ApolloQueryResult<GetAdminCompanyDetailsQuery>>;
  customerName: string;
  customerId: string;
}

export const AddSiteDrawer = ({
  setOpenAddSite,
  refetchCompany,
  customerName,
  customerId,
  ...props
}: AddSiteDrawerProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sendMessageToSnackbar } = useEnqueueSnackbar();
  const TIMEZONE_AUTO_DETECT = t('companyAdminDetailPage.addSiteDrawer.autoDetect');
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [createSite, { loading: loadingCreateSite }] = useMutation(MUTATION_CREATE_SITE, {
    context: { timeout: graphqlApiConfig.mutationTimeout },
    onError: (error) =>
      sendMessageToSnackbar(
        t('companyAdminDetailPage.addSiteDrawer.createSiteFailed'),
        undefined,
        error.message || error.name,
        'error'
      )
  });
  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty }
  } = useForm<AddSiteRequest>({
    resolver: zodResolver(addSiteSchema),
    defaultValues: { id: customerId, timezone: TIMEZONE_AUTO_DETECT }
  });

  const customerSelectOption: RSSelectItemProps = { displayName: customerName, menuItemProps: { value: customerId } };
  const timezoneOptions: RSSelectItemProps[] = [TIMEZONE_AUTO_DETECT, ...browserSupportedTimezones].map((timezone) => ({
    displayName: timezone,
    menuItemProps: { value: timezone }
  }));

  const handleLeave = (): void => {
    reset();
    setShowConfirmationModal(false);
    setOpenAddSite(false);
  };

  const handleCancel = (): void => {
    if (isDirty) {
      setShowConfirmationModal(true);
    } else {
      reset();
      setOpenAddSite(false);
    }
  };

  const onSubmit: SubmitHandler<AddSiteRequest> = (data): void => {
    if (!isDirty) {
      setOpenAddSite(false);
      setShowConfirmationModal(false);
      return;
    }

    const dataToSubmit: CreateSiteMutationVariables = {
      ...data,
      id: customerId,
      timezone: data.timezone === TIMEZONE_AUTO_DETECT ? undefined : data.timezone
    };

    createSite({
      variables: dataToSubmit,
      onCompleted: (responseData) => {
        sendMessageToSnackbar(
          t('companyAdminDetailPage.addSiteDrawer.createSiteSuccess'),
          undefined,
          undefined,
          'success'
        );
        setOpenAddSite(false);
        setShowConfirmationModal(false);
        reset(data);
        refetchCompany();
        navigate(`${appConfig.basePath}/admin/sites/${responseData.createSite.id}`);
      }
    });
  };

  return (
    <RSDrawer {...props} className="add-site-drawer">
      <ModalDrawerHeader title="Add site" />
      <form className="add-site-drawer__form" onSubmit={handleSubmit(onSubmit)} data-testid="add-site-drawer-form">
        <div className="add-site-drawer__input-fields">
          <RSTextInput
            inputLabel={t('companyAdminDetailPage.addSiteDrawer.siteName')}
            required={true}
            data-testid="add-site-drawer-name-input"
            helperText={errors.name?.message}
            error={Boolean(errors.name)}
            {...register('name')}
          />
          <RSSelect
            className="add-company-drawer__customer-select"
            inputLabel={t('companyAdminDetailPage.addSiteDrawer.customer')}
            required={true}
            disabled={true}
            menuItems={[customerSelectOption]}
            helperText={errors.id?.message}
            error={Boolean(errors.id)}
            data-testid="add-company-drawer-customer-select"
            defaultValue={customerId}
            {...register('id')}
          />
          <RSTextInput
            inputLabel={t('companyAdminDetailPage.addSiteDrawer.address')}
            data-testid="add-site-drawer-address-input"
            helperText={errors.location?.formattedAddress?.message}
            error={Boolean(errors.location?.formattedAddress)}
            {...register('location.formattedAddress')}
          />
          <RSTextInput
            inputLabel={t('companyAdminDetailPage.addSiteDrawer.latitude')}
            type="number"
            required={true}
            helperText={errors.location?.latitude?.message}
            error={Boolean(errors.location?.latitude)}
            data-testid="add-site-drawer-latitude-input"
            {...register('location.latitude', {
              valueAsNumber: true,
              onChange: () => setValue('timezone', TIMEZONE_AUTO_DETECT)
            })}
          />
          <RSTextInput
            inputLabel={t('companyAdminDetailPage.addSiteDrawer.longitude')}
            type="number"
            required={true}
            helperText={errors.location?.longitude?.message}
            error={Boolean(errors.location?.longitude)}
            data-testid="add-site-drawer-longitude-input"
            {...register('location.longitude', {
              valueAsNumber: true,
              onChange: () => setValue('timezone', TIMEZONE_AUTO_DETECT)
            })}
          />
          <Controller
            name="timezone"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <RSSelect
                inputLabel={t('companyAdminDetailPage.addSiteDrawer.timezone')}
                menuItems={timezoneOptions}
                helperText={errors.timezone?.message}
                error={Boolean(errors.timezone)}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                data-testid="add-site-drawer-timezone-select"
              />
            )}
          />
          <RSTextInput
            inputLabel={t('companyAdminDetailPage.addSiteDrawer.description')}
            data-testid="add-site-drawer-site-input"
            multiline={true}
            helperText={errors.description?.message}
            error={Boolean(errors.description)}
            {...register('description')}
          />
        </div>
        <DrawerButtonsGroup
          handleCancel={handleCancel}
          handleSave={handleSubmit(onSubmit)}
          isSaveDisabled={loadingCreateSite}
          isCancelDisabled={loadingCreateSite}
          colorVariant="success"
          saveButtonText={t('companyAdminDetailPage.addSiteDrawer.submitForm')}
        />
      </form>
      <ConfirmationModal
        open={showConfirmationModal}
        mainTitle={t('forms.confirmationModal.confirmModalTitle')}
        message={t('forms.confirmationModal.unsavedChangesMessage')}
        confirmButtonText={t('forms.confirmationModal.confirmActionButtonText')}
        cancelButtonText={t('forms.confirmationModal.cancelActionButtonText')}
        handleClickCancelButton={handleLeave}
        handleClickConfirmButton={() => setShowConfirmationModal(false)}
        disableConfirmButton={loadingCreateSite}
        disableCancelButton={loadingCreateSite}
        confirmButtonColor="success"
        cancelButtonColor="success"
      />
    </RSDrawer>
  );
};

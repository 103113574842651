import { isEmpty } from 'lodash';

import { GetAdminDeviceGroupDetailsQuery } from '../../../../__generated__/graphql';
import { Flatten } from '../../../../types';
import { formatTimestamp, getNonNullishDisplayValue } from '../../../../utilities';

export const generateDeviceRows = (
  devices: NonNullable<GetAdminDeviceGroupDetailsQuery['deviceGroupByPK']>['deviceGroupDevices'],
  userTimezone?: string
) => {
  if (isEmpty(devices)) {
    return [];
  }

  return devices.map((device) => ({
    id: device.deviceId,
    serialNumber: getNonNullishDisplayValue(device.device.serialNumber),
    program: getNonNullishDisplayValue(device.device.program?.name),
    type: device.device.deviceType.modelNumber,
    connectorHolderType: getNonNullishDisplayValue(device.device.connectorHolderType?.name),
    createdAt: formatTimestamp(device.createdAt, userTimezone),
    updatedAt: formatTimestamp(device.updatedAt, userTimezone)
  }));
};

export type DeviceGroupDeviceRow = Flatten<ReturnType<typeof generateDeviceRows>>;

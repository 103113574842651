import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { GetAdminSiteDetailsQuery } from '../../../../__generated__/graphql';
import { appConfig } from '../../../../configs';
import { getNonNullishDisplayValue } from '../../../../utilities';
import { AdminDetailsPanelTitle } from '../../../4-features';
import { InformationBlock } from '../../../5-elements';
import { useFormatTimezone } from '../../../hooks';

interface SiteDetailsAdminPanelProps {
  // This panel will only be shown if the site details are successfully loaded
  siteDetails: NonNullable<GetAdminSiteDetailsQuery['siteByPK']>;
}

export const SiteDetailsAdminPanel = ({ siteDetails }: SiteDetailsAdminPanelProps): JSX.Element => {
  const { t } = useTranslation();
  const { formatWithDefaultTimezone } = useFormatTimezone();

  return (
    <aside className="site-details-admin-panel" data-testid="site-details-admin-panel">
      <AdminDetailsPanelTitle
        objectType={t('siteAdminDetailsPage.panel.type')}
        objectName={siteDetails.name}
        goBackUrl={`${appConfig.basePath}/admin/companies/${siteDetails.customer.companyId}`}
        editButtonProps={{ disabled: true }}
      />
      <div className="site-details-admin-panel__contents">
        <InformationBlock
          label={t('siteAdminDetailsPage.panel.customer')}
          data-testid="site-details-admin-panel-customer"
          value={siteDetails.customer.company.name}
        />
        <InformationBlock
          label={t('siteAdminDetailsPage.panel.address')}
          data-testid="site-details-admin-panel-address"
          value={getNonNullishDisplayValue(siteDetails.location.formattedAddress)}
        />
        <InformationBlock
          label={t('siteAdminDetailsPage.panel.latitude')}
          data-testid="site-details-admin-panel-latitude"
          value={siteDetails.location.latitude}
        />
        <InformationBlock
          label={t('siteAdminDetailsPage.panel.longitude')}
          data-testid="site-details-admin-panel-longitude"
          value={siteDetails.location.longitude}
        />
        <InformationBlock
          label={t('siteAdminDetailsPage.panel.timezone')}
          data-testid="site-details-admin-panel-timezone"
          value={siteDetails.timezoneName}
        />
        <InformationBlock
          label={t('siteAdminDetailsPage.panel.description')}
          data-testid="site-details-admin-panel-description"
          value={getNonNullishDisplayValue(siteDetails.description, true)}
        />
        <InformationBlock
          label={t('siteAdminDetailsPage.panel.createdAt')}
          data-testid="site-details-admin-panel-created-at"
          value={formatWithDefaultTimezone(siteDetails.createdAt)}
        />
        <InformationBlock
          label={t('siteAdminDetailsPage.panel.updatedAt')}
          data-testid="site-details-admin-panel-updated-at"
          value={formatWithDefaultTimezone(siteDetails.updatedAt)}
        />
      </div>
    </aside>
  );
};

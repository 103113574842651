import { isEmpty } from 'lodash';

import { GetAdminSiteDetailsQuery } from '../../../../__generated__/graphql';
import i18n from '../../../../i18n';
import { Flatten } from '../../../../types';
import { contextualizeBoolean, formatTimestamp, getNonNullishDisplayValue } from '../../../../utilities';

export const generateDeviceRows = (
  devices: NonNullable<GetAdminSiteDetailsQuery['siteByPK']>['devices'],
  userTimezone?: string
) => {
  if (isEmpty(devices)) {
    return [];
  }

  return devices.map((device) => ({
    id: device.id,
    serialNumber: getNonNullishDisplayValue(device.serialNumber),
    deviceGroup: device.deviceGroupDevicesAggregate.aggregate?.count || 0,
    program: getNonNullishDisplayValue(device.program?.name),
    type: device.deviceType.modelNumber,
    connectorHolderType: getNonNullishDisplayValue(device.connectorHolderType?.name),
    deactivated: contextualizeBoolean(
      i18n.t('siteAdminDetailsPage.deviceDataGrid.deactivated'),
      i18n.t('siteAdminDetailsPage.deviceDataGrid.activated'),
      device.deactivated
    ),
    createdAt: formatTimestamp(device.createdAt, userTimezone),
    updatedAt: formatTimestamp(device.updatedAt, userTimezone)
  }));
};

export type SiteDeviceRow = Flatten<ReturnType<typeof generateDeviceRows>>;

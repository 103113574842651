import {
  CompanyOrderBy,
  CustomerOrderBy,
  DeviceOperationBoolExp,
  DeviceOperationOrderBy,
  DeviceOrderBy,
  OrderBy,
  UserOrderBy
} from '../__generated__/graphql';
import { EnvironmentSetting } from '../types/environment-setting';

export const PORTAL_CLIENT_ID: EnvironmentSetting = {
  DEV: 'MD0sqf9rgSp1OON9WpMXHapKVogWlHjR',
  INT: 'MD0sqf9rgSp1OON9WpMXHapKVogWlHjR',
  ACC: 'hoNfFcAIBvKJcK3InROpWqrPlj7oby5T',
  PROD: 'b92Ikrxg3qdtBifLJUAHqoDwIqBHurc5'
};

export const PORTAL_AUDIENCE: EnvironmentSetting = {
  DEV: 'https://cloud-portal.rocsys.int',
  INT: 'https://cloud-portal.rocsys.int',
  ACC: 'https://cloud-portal.rocsys.acc',
  PROD: 'https://cloud-portal.rocsys.prod'
};

export const GRAPHQL_ENDPOINT_URL: EnvironmentSetting = {
  DEV: 'http://localhost:8080',
  INT: '',
  ACC: '',
  PROD: ''
};

export const GRAPHQL_ENDPOINT_WS: EnvironmentSetting = {
  DEV: 'ws://localhost:8080',
  INT: '',
  ACC: '',
  PROD: ''
};

export const SHOW_ADMIN_NAV_ITEM: EnvironmentSetting = {
  DEV: true,
  INT: true,
  ACC: false,
  PROD: false
};

// Data grids
export const DEFAULT_PAGE_SIZE = 25;
export const DEFAULT_GET_CUSTOMERS_SORT_BY: CustomerOrderBy[] = [{ company: { name: OrderBy.Asc } }];
export const DEFAULT_GET_DEVICES_SORT_BY: DeviceOrderBy[] = [{ serialNumber: OrderBy.Asc }];
export const DEFAULT_GET_OPERATIONS_SORT_BY: DeviceOperationOrderBy[] = [{ startAt: OrderBy.Desc }];
export const DEFAULT_GET_OPERATIONS_FILTER: DeviceOperationBoolExp = { operationName: { _eq: 'ACDCycle' } };
export const DEFAULT_GET_ADMIN_COMPANIES_SORT_BY: CompanyOrderBy[] = [{ name: OrderBy.Asc }];
export const DEFAULT_GET_ADMIN_DEVICES_SORT_BY: DeviceOrderBy[] = [{ serialNumber: OrderBy.Asc }];
export const DEFAULT_GET_ADMIN_USERS_SORT_BY: UserOrderBy[] = [{ firstName: OrderBy.Asc }, { lastName: OrderBy.Asc }];

// Performance metrics
export const DATE_FORMAT_TEMPLATE_PERFORMANCE_STATS = 'yyyy-MM-dd HH:mm:ss Z';

// Admin pages
export const COMPANIES_ADMIN_PAGE_PATH = '/portal/admin/companies';
export const USERS_ADMIN_PAGE_PATH = '/portal/admin/users';
export const DEVICES_ADMIN_PAGE_PATH = '/portal/admin/devices';

export const DEFAULT_REFRESH_INTERVAL = 10000;

import { JSX } from 'react';

import ArrowLeftIcon from '../../../../assets/icons/arrow-left.svg?react';
import { InternalLink } from '../../../5-elements';
import { EditButton, EditButtonProps } from '../../edit-button';

interface AdminDetailsPanelTitleProps {
  objectType: string;
  objectName: string;
  goBackUrl: string;
  editButtonProps?: EditButtonProps;
}

export const AdminDetailsPanelTitle = ({
  objectType,
  objectName,
  goBackUrl,
  editButtonProps
}: AdminDetailsPanelTitleProps): JSX.Element => {
  return (
    <div className="admin-details-panel-title" data-testid="admin-details-panel-title">
      <div className="admin-details-panel-title__object-type">{objectType}</div>
      <div className="admin-details-panel-title__object-name-bar">
        <div className="admin-details-panel-title__object-name-title">
          <div className="admin-details-panel-title__go-back-button">
            <InternalLink to={goBackUrl} icon={<ArrowLeftIcon />} />
          </div>
          <h2 className="admin-details-panel-title__object-name">{objectName}</h2>
        </div>
        <EditButton {...editButtonProps} />
      </div>
    </div>
  );
};

import {
  FormControl,
  FormGroup,
  FormGroupProps,
  FormHelperText,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps
} from '@mui/material';
import classNames from 'classnames';
import { JSX, ReactNode } from 'react';

interface RSCheckboxGroup extends FormGroupProps {
  /**
   * The label of the radio group.
   */
  formLabel?: ReactNode;
  /**
   * The properties for the radio group label.
   */
  formLabelProps?: FormLabelProps;
  /**
   * The helper text of the radio group.
   */
  formHelperText?: ReactNode;
  /**
   * The properties for the helper text of the radio group.
   */
  formHelperTextProps?: FormHelperTextProps;
}

export const RSCheckboxGroup = ({
  children,
  formLabel,
  formLabelProps,
  formHelperText,
  formHelperTextProps,
  ...props
}: RSCheckboxGroup): JSX.Element => {
  return (
    <FormControl className="rs-checkbox-group__form-control">
      {formLabel && (
        <FormLabel
          {...formLabelProps}
          className={classNames('rs-checkbox-group__label', formLabelProps?.className)}
          data-testid="rs-checkbox-group-label"
        >
          {formLabel}
        </FormLabel>
      )}
      <FormGroup {...props} className={classNames('rs-checkbox-group', props.className)}>
        {children}
      </FormGroup>
      {formHelperText && (
        <FormHelperText
          {...formHelperTextProps}
          className={classNames('rs-checkbox-group__helper-text', formHelperTextProps?.className)}
          data-testid="rs-checkbox-group-helper-text"
        >
          {formHelperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

import { gql } from '../../../../__generated__/gql';

export const MUTATION_CREATE_CUSTOMER = gql(`
  mutation createCustomer($name: String!, $allowImport: Boolean!, $maxDeviceGroupRole: UserDeviceGroupUserRole!) {
    createCustomer(name: $name, allowImport: $allowImport, maxDeviceGroupRole: $maxDeviceGroupRole) {
      id
      customer {
        companyId
        company {
          id
          name
          maxDeviceGroupRole
          customer {
            companyId
            allowImport
          }
        }
      }
    }
  }
`);

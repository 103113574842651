import { useQuery } from '@apollo/client';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import { get, isBoolean } from 'lodash';
import qs from 'qs';
import { JSX, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import { DeviceDetailsSearchParams, deviceDetailsSearchParamsSchema } from './device-details-search-params';
import { DeviceDetailsSidePanel } from './device-details-side-panel';
import { DeviceHealthCards } from './device-health-cards';
import { DeviceLatestOperations } from './device-latest-operations';
import ArrowLeftIcon from '../../../assets/icons/arrow-left.svg?react';
import OfflineIcon from '../../../assets/icons/offline.svg?react';
import { appConfig } from '../../../configs';
import { DEFAULT_REFRESH_INTERVAL } from '../../../constants';
import { useAuthCheckerWithSubjectInfo } from '../../../services/authz-checker';
import { QUERY_GET_DEVICE_DETAILS } from '../../../services/queries';
import { filterValidUrlFields, getDeviceMeasurementValue, getPerformanceMetricsValues } from '../../../utilities';
import { DetailsMainSectionWrapper } from '../../2-templates';
import { OperationDetails } from '../../3-sections';
import { PerformanceMetricsRender } from '../../4-features';
import { InternalLink, WarningStrip } from '../../5-elements';
import { AutoRefreshContext } from '../../contexts';
import { useFormatTimezone } from '../../hooks';
import { ErrorPage } from '../error-page';
import { PageNotFoundErrorPage } from '../error-page/page-not-found-error-page';
import { LoadingPage } from '../loading-page';

export const DeviceDetailsPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { deviceId } = useParams();
  const routerLocation = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { formatWithDefaultTimezone } = useFormatTimezone();
  const { autoRefresh } = useContext(AutoRefreshContext);
  const [selectedOperationId, setSelectedOperationId] = useState<string | undefined>(undefined);
  const { loading, data, error, refetch, startPolling, stopPolling } = useQuery(QUERY_GET_DEVICE_DETAILS, {
    variables: { deviceId: deviceId! }
  });
  // Put the auth checker here so that it can be part of the loading and error handling flow. It will be used for both
  // the site impression and the edit device components.
  const { result: userCanEditDevice, loading: loadingUserCanEditDevice } = useAuthCheckerWithSubjectInfo({
    action: 'UPDATE',
    subjectInfo: { type: 'Device', deviceId: deviceId || '' },
    skip: !deviceId
  });
  const { result: userCanViewDeviceGroupDevice, loading: loadingUserCanViewDeviceGroupDevice } =
    useAuthCheckerWithSubjectInfo({
      action: 'READ',
      subjectInfo: { type: 'Device', deviceId: deviceId || '' },
      skip: !deviceId
    });

  if (autoRefresh) {
    startPolling(DEFAULT_REFRESH_INTERVAL);
  } else {
    stopPolling();
  }

  const handleOperationDetailsClose = (): void => {
    searchParams.delete('operationId');
    setSearchParams(searchParams);
  };

  const searchParameters = qs.parse(searchParams.toString());

  const validUrlFields = filterValidUrlFields<DeviceDetailsSearchParams>(
    searchParameters,
    deviceDetailsSearchParamsSchema
  );

  useEffect(() => {
    // Set operation id
    const urlOperationId = get(validUrlFields, 'operationId');
    if (urlOperationId !== selectedOperationId) {
      setSelectedOperationId(urlOperationId);
    }
  }, [routerLocation.search]);

  if (error) {
    const isUUIDError = error.name === 'ApolloError' && error.message.includes('invalid input syntax for type uuid');

    if (isUUIDError) {
      return <PageNotFoundErrorPage />;
    }

    return (
      <ErrorPage
        titleEmphasized={t('apolloErrorPage.errorCode')}
        title={t('apolloErrorPage.errorTitle')}
        message={error.message}
      />
    );
  }

  const isLoading =
    loading ||
    loadingUserCanEditDevice ||
    loadingUserCanViewDeviceGroupDevice ||
    !isBoolean(userCanViewDeviceGroupDevice);

  if (isLoading) {
    return <LoadingPage />;
  }

  const device = data?.deviceByPK;

  if (!device) {
    return <PageNotFoundErrorPage />;
  }

  const connectivityMeasurement = getDeviceMeasurementValue('is_online', device.deviceMeasurementValues);
  const devicePerformance = device.deviceCurrentPerformance;
  const performanceValues = getPerformanceMetricsValues(
    devicePerformance?.successLastPeriod || null,
    devicePerformance?.totalLastPeriod || null,
    devicePerformance?.successPrevPeriod || null,
    devicePerformance?.totalPrevPeriod || null
  );

  return (
    <DetailsMainSectionWrapper
      panel={<DeviceDetailsSidePanel device={device} canUserEditDevice={userCanEditDevice} />}
      extraClassNames={['device-details-page__wrapper']}
    >
      {connectivityMeasurement && connectivityMeasurement.value === 0 && (
        <WarningStrip
          variant="warning"
          text={t('deviceDetailsPage.offlineWarningBar', {
            timestamp: formatWithDefaultTimezone(connectivityMeasurement.updatedAt)
          })}
          icon={<OfflineIcon className="device-details-page__offline-icon" />}
        />
      )}
      {userCanViewDeviceGroupDevice === false && (
        <WarningStrip text={t('deviceDetailsPage.limitedAccessWarningBar')} icon={<HttpsOutlinedIcon />} />
      )}
      <section className="device-details-page" data-testid="device-details-page">
        <div className="device-details-page__device-health">
          <div className="device-details-page__title-bar">
            <h2 className="device-details-page__system-health-status">{t('deviceDetailsPage.deviceHealth.title')}</h2>
            <PerformanceMetricsRender
              acdCycleOperationsSuccessRate={performanceValues.acdCycleOperationsSuccessRate}
              acdCycleOperationsSuccessRateChange={performanceValues.acdCycleOperationsSuccessRateChange}
              acdCycleOperationsCount={performanceValues.acdCycleOperationsCount}
              acdCycleOperationsCountChange={performanceValues.acdCycleOperationsCountChange}
              loadingTotalAcdCyclesOperationResult={isLoading}
              errorTotalAcdCyclesOperationResult={Boolean(error)}
            />
          </div>
          <DeviceHealthCards
            canUserEditDevice={userCanEditDevice}
            deviceId={device.id}
            deviceLastState={device.deviceLastState}
            deviceMeasurementValues={device.deviceMeasurementValues}
          />
        </div>
        <div className="device-details-page__operations">
          <div className="device-details-page__operations-title-bar">
            <h3>{t('deviceDetailsPage.operations.title')}</h3>
            <InternalLink
              to={`${appConfig.basePath}/operations?serialNumber=${device.serialNumber}`}
              icon={<ArrowLeftIcon />}
              text={t('deviceDetailsPage.operations.allOperationsLinkText')}
              iconPosition="right"
            />
          </div>
          <div className="device-details-page__data-grid">
            <DeviceLatestOperations
              operations={device.deviceOperations}
              deviceId={device.id}
              serialNumber={device.serialNumber}
            />
          </div>
        </div>
        <OperationDetails
          operationId={selectedOperationId}
          open={Boolean(selectedOperationId)}
          handleOperationDetailsClose={handleOperationDetailsClose}
          source="device-details"
          refetch={refetch}
        />
      </section>
    </DetailsMainSectionWrapper>
  );
};

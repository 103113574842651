import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { GetAdminDeviceGroupDetailsQuery } from '../../../../__generated__/graphql';
import { appConfig } from '../../../../configs';
import { getNonNullishDisplayValue } from '../../../../utilities';
import { AdminDetailsPanelTitle } from '../../../4-features';
import { InformationBlock } from '../../../5-elements';
import { useFormatTimezone } from '../../../hooks';

interface DeviceGroupDetailsAdminPanelProps {
  // This panel will only be shown if the site details are successfully loaded
  deviceGroupDetails: NonNullable<GetAdminDeviceGroupDetailsQuery['deviceGroupByPK']>;
}

export const DeviceGroupDetailsAdminPanel = ({
  deviceGroupDetails
}: DeviceGroupDetailsAdminPanelProps): JSX.Element => {
  const { t } = useTranslation();
  const { formatWithDefaultTimezone } = useFormatTimezone();

  return (
    <aside className="device-group-details-admin-panel" data-testid="device-group-details-admin-panel">
      <AdminDetailsPanelTitle
        objectType={t('deviceGroupDetailsPage.panel.type')}
        objectName={deviceGroupDetails.name}
        goBackUrl={`${appConfig.basePath}/admin/companies/${deviceGroupDetails.customer.companyId}`}
        editButtonProps={{ disabled: true }}
      />
      <div className="device-group-details-admin-panel__contents">
        <InformationBlock
          label={t('deviceGroupDetailsPage.panel.customer')}
          data-testid="device-group-details-admin-panel-customer"
          value={deviceGroupDetails.customer.company.name}
        />
        <InformationBlock
          label={t('deviceGroupDetailsPage.panel.serviceProvider')}
          data-testid="device-group-details-admin-panel-service-provider"
          value={getNonNullishDisplayValue(deviceGroupDetails.serviceProvider?.company.name)}
        />
        <InformationBlock
          label={t('deviceGroupDetailsPage.panel.description')}
          data-testid="device-group-details-admin-panel-description"
          value={getNonNullishDisplayValue(deviceGroupDetails.description, true)}
        />
        <InformationBlock
          label={t('deviceGroupDetailsPage.panel.createdAt')}
          data-testid="device-group-details-admin-panel-created-at"
          value={formatWithDefaultTimezone(deviceGroupDetails.createdAt)}
        />
        <InformationBlock
          label={t('deviceGroupDetailsPage.panel.updatedAt')}
          data-testid="device-group-details-admin-panel-updated-at"
          value={formatWithDefaultTimezone(deviceGroupDetails.updatedAt)}
        />
      </div>
    </aside>
  );
};

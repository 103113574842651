import classNames from 'classnames';
import { JSX } from 'react';
import { LinkProps } from 'react-router-dom';

import { RSLinkTimezone } from '../../4-features/rs-link-timezone';

interface InternalLinkProps extends LinkProps {
  icon?: JSX.Element;
  iconPosition?: 'left' | 'right';
  text?: string;
  disabled?: boolean;
  to: string;
}

export const InternalLink = ({ icon, iconPosition, text, disabled, ...props }: InternalLinkProps): JSX.Element => {
  const InternalLinkBase = ({ icon, text }: Pick<InternalLinkProps, 'icon' | 'text'>): JSX.Element => {
    return (
      <>
        {icon && (
          <div className="internal-link__icon-container" data-testid="internal-link-icon">
            {icon}
          </div>
        )}
        {text && (
          <p className="internal-link__text" data-testid="internal-link-text">
            {text}
          </p>
        )}
      </>
    );
  };

  const iconPositionClass = iconPosition === 'right' ? 'internal-link--icon-right' : '';

  if (disabled) {
    return (
      <div
        className={classNames('internal-link', 'internal-link--disabled', iconPositionClass)}
        data-testid="internal-link-disabled"
      >
        <InternalLinkBase icon={icon} text={text} />
      </div>
    );
  }

  return (
    <RSLinkTimezone {...props} className={classNames('internal-link', iconPositionClass)} data-testid="internal-link">
      <InternalLinkBase icon={icon} text={text} />
    </RSLinkTimezone>
  );
};

import { useQuery } from '@apollo/client';
import { JSX, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { QUERY_GET_ADMIN_DEVICE_GROUP_DETAILS } from '../../../services/queries';
import { AdminDetailsMainSectionWrapper } from '../../2-templates';
import { RSButton } from '../../5-elements';
import { ErrorPage, PageNotFoundErrorPage } from '../error-page';
import { LoadingPage } from '../loading-page';
import {
  deviceGroupDevicesDataGridColumns,
  deviceGroupUsersDataGridColumns,
  generateDeviceRows,
  generateUserRows
} from './data-grid-configurations';
import { DeviceGroupDetailsAdminPanel } from './device-group-details-admin-panel';
import { AdminDetailsDataGrid } from '../../4-features';
import { UserTimezoneContext } from '../../contexts';

export const DeviceGroupDetailsAdminPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { deviceGroupId } = useParams();
  const { userTimezone } = useContext(UserTimezoneContext);
  const { data, loading, error } = useQuery(QUERY_GET_ADMIN_DEVICE_GROUP_DETAILS, {
    variables: { deviceGroupId: deviceGroupId! }
  });

  if (error) {
    const isUUIDError = error.name === 'ApolloError' && error.message.includes('invalid input syntax for type uuid');

    if (isUUIDError) {
      return <PageNotFoundErrorPage />;
    }

    return (
      <ErrorPage
        titleEmphasized={t('apolloErrorPage.errorCode')}
        title={t('apolloErrorPage.errorTitle')}
        message={error.message}
      />
    );
  }

  if (loading) {
    return <LoadingPage />;
  }

  const deviceGroupDetails = data?.deviceGroupByPK;
  if (!deviceGroupDetails) {
    return <PageNotFoundErrorPage />;
  }

  return (
    <AdminDetailsMainSectionWrapper panel={<DeviceGroupDetailsAdminPanel deviceGroupDetails={deviceGroupDetails} />}>
      <div className="device-group-details-admin-page" data-testid="device-group-details-admin-page">
        <div className="device-group-details-admin-page__devices" data-testid="device-group-details-admin-page-devices">
          <div className="device-group-details-admin-page__title-bar">
            <h2>{t('deviceGroupDetailsPage.deviceDataGrid.title')}</h2>
            <RSButton
              color="success"
              disabled={true}
              variant="contained"
              data-testid="device-group-details-admin-page-manage-devices"
            >
              {t('deviceGroupDetailsPage.deviceDataGrid.manage')}
            </RSButton>
          </div>
          <div className="device-group-details-admin-page__data-grid">
            <AdminDetailsDataGrid
              columns={deviceGroupDevicesDataGridColumns}
              loading={loading}
              rows={generateDeviceRows(deviceGroupDetails.deviceGroupDevices, userTimezone)}
              data-testid="device-group-details-admin-page-devices-data-grid"
            />
          </div>
        </div>
        <div className="device-group-details-admin-page__users" data-testid="device-group-details-admin-page-users">
          <div className="device-group-details-admin-page__title-bar">
            <h2>{t('deviceGroupDetailsPage.userDataGrid.title')}</h2>
            <RSButton
              color="success"
              disabled={true}
              variant="contained"
              data-testid="device-group-details-admin-page-manage-users"
            >
              {t('deviceGroupDetailsPage.userDataGrid.manage')}
            </RSButton>
          </div>
          <div className="device-group-details-admin-page__data-grid">
            <AdminDetailsDataGrid
              columns={deviceGroupUsersDataGridColumns}
              loading={loading}
              rows={generateUserRows(deviceGroupDetails.userDeviceGroups, userTimezone)}
              data-testid="device-group-details-admin-page-users-data-grid"
            />
          </div>
        </div>
      </div>
    </AdminDetailsMainSectionWrapper>
  );
};

import { useQuery } from '@apollo/client';
import { JSX, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { deviceDeviceGroupDataGridColumns, generateDeviceGroupRows } from './data-grid-configurations';
import { QUERY_GET_ADMIN_DEVICE_DETAILS } from '../../../services/queries';
import { AdminDetailsMainSectionWrapper } from '../../2-templates';
import { AdminDetailsDataGrid } from '../../4-features';
import { RSButton } from '../../5-elements';
import { UserTimezoneContext } from '../../contexts';
import { ErrorPage, PageNotFoundErrorPage } from '../error-page';
import { LoadingPage } from '../loading-page';
import { DeviceDetailsAdminPanel } from './device-details-admin-panel';

export const DeviceDetailsAdminPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { deviceId } = useParams();
  const { userTimezone } = useContext(UserTimezoneContext);
  const { data, loading, error } = useQuery(QUERY_GET_ADMIN_DEVICE_DETAILS, { variables: { deviceId: deviceId! } });

  if (error) {
    const isUUIDError = error.name === 'ApolloError' && error.message.includes('invalid input syntax for type uuid');

    if (isUUIDError) {
      return <PageNotFoundErrorPage />;
    }

    return (
      <ErrorPage
        titleEmphasized={t('apolloErrorPage.errorCode')}
        title={t('apolloErrorPage.errorTitle')}
        message={error.message}
      />
    );
  }

  if (loading) {
    return <LoadingPage />;
  }

  const deviceDetails = data?.deviceByPK;
  if (!deviceDetails) {
    return <PageNotFoundErrorPage />;
  }

  return (
    <AdminDetailsMainSectionWrapper panel={<DeviceDetailsAdminPanel deviceDetails={deviceDetails} />}>
      <div className="device-details-admin-page" data-testid="device-details-admin-page">
        <div className="device-details-admin-page__device-groups" data-testid="device-details-admin-page-device-groups">
          <div className="device-details-admin-page__title-bar">
            <h2>{t('deviceAdminDetailsPage.deviceGroupsDataGrid.title')}</h2>
            <RSButton
              disabled={true}
              color="success"
              variant="contained"
              data-testid="device-details-admin-page-manage-device-groups"
            >
              {t('deviceAdminDetailsPage.deviceGroupsDataGrid.manage')}
            </RSButton>
          </div>
          <div className="device-details-admin-page__data-grid">
            <AdminDetailsDataGrid
              columns={deviceDeviceGroupDataGridColumns}
              loading={loading}
              rows={generateDeviceGroupRows(deviceDetails.deviceGroupDevices, userTimezone)}
              data-testid="device-details-admin-page-device-groups-data-grid"
            />
          </div>
        </div>
      </div>
    </AdminDetailsMainSectionWrapper>
  );
};

import { useTranslation } from 'react-i18next';

import { GetAdminCompanyDetailsQuery, UserDeviceGroupRole } from '../../../../__generated__/graphql';
import { appConfig } from '../../../../configs';
import { CompanyType } from '../../../../types';
import {
  contextualizeBoolean,
  getNonNullishDisplayValue,
  mapCompanyType,
  mapUserDeviceGroupRole
} from '../../../../utilities';
import { AdminDetailsPanelTitle } from '../../../4-features';
import { InformationBlock } from '../../../5-elements';
import { useFormatTimezone } from '../../../hooks';

interface CompanyDetailsAdminPanelProps {
  // This panel will only be shown if the company details are successfully loaded
  companyDetails: NonNullable<GetAdminCompanyDetailsQuery['companyByPK']>;
}

export const CompanyDetailsAdminPanel = ({ companyDetails }: CompanyDetailsAdminPanelProps) => {
  const { t } = useTranslation();
  const { formatWithDefaultTimezone } = useFormatTimezone();
  const isCustomer = companyDetails.companyType === CompanyType.Customer;

  return (
    <aside className="company-details-admin-panel" data-testid="company-details-admin-panel">
      <AdminDetailsPanelTitle
        objectType={mapCompanyType(companyDetails.companyType as CompanyType)}
        objectName={companyDetails.name}
        goBackUrl={`${appConfig.basePath}/admin/companies`}
        editButtonProps={{ disabled: true }}
      />
      <div className="company-details-admin-panel__contents">
        {isCustomer && (
          <InformationBlock
            label={t('companyAdminDetailPage.panel.allowImport')}
            data-testid="company-details-admin-panel-allow-import"
            value={contextualizeBoolean(
              t('companyAdminDetailPage.panel.allowImportValues.enabled'),
              t('companyAdminDetailPage.panel.allowImportValues.disabled'),
              companyDetails.customer?.allowImport
            )}
          />
        )}
        <InformationBlock
          label={t('companyAdminDetailPage.panel.maxDeviceGroupRole')}
          data-testid="company-details-admin-panel-max-device-group-role"
          value={mapUserDeviceGroupRole(companyDetails.maxDeviceGroupRole as UserDeviceGroupRole)}
        />
        {isCustomer && (
          <InformationBlock
            label={t('companyAdminDetailPage.panel.ipAcceptList')}
            data-testid="company-details-admin-panel-ip-list"
            value={getNonNullishDisplayValue(companyDetails.companySettings.rocsysApiIpAcceptlist)}
          />
        )}
        <InformationBlock
          label={t('companyAdminDetailPage.panel.createdAt')}
          data-testid="company-details-admin-panel-created-at"
          value={formatWithDefaultTimezone(companyDetails.createdAt)}
        />
        <InformationBlock
          label={t('companyAdminDetailPage.panel.updatedAt')}
          data-testid="company-details-admin-panel-updated-at"
          value={formatWithDefaultTimezone(companyDetails.updatedAt)}
        />
      </div>
    </aside>
  );
};

import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { isEqual } from 'lodash';
import { JSX, SetStateAction, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  DeviceOperationalLifeCycleRemarksEditRequest,
  deviceOperationalLifeCycleRemarksEditSchema
} from './validation-schema';
import { OperationalLifeCycle } from '../../../../__generated__/graphql';
import { graphqlApiConfig } from '../../../../configs';
import { MUTATION_MODIFY_DEVICE } from '../../../../services/mutations/devices/modify-device';
import { DeviceOperationalLifeCycleRemarksDrawerProps } from '../../../../types';
import { getNonNullishDisplayValue, mapOperationalLifeCycleDisplayLabel } from '../../../../utilities';
import { RSDrawer, RSDrawerProps } from '../../../3-sections';
import { ConfirmationModal, DrawerButtonsGroup, EditButton, ModalDrawerHeader } from '../../../4-features';
import { InformationBlock, RSSelect, RSSelectItemProps, RSTextInput } from '../../../5-elements';
import { useEnqueueSnackbar } from '../../../hooks';

interface DeviceOperationalLifeCycleRemarksProps extends Omit<RSDrawerProps, 'children'> {
  defaultValues: DeviceOperationalLifeCycleRemarksEditRequest;
  setOpenDeviceOperationalLifeCycleRemarks: (
    value: SetStateAction<DeviceOperationalLifeCycleRemarksDrawerProps>
  ) => void;
  mode?: 'view' | 'edit';
  startMode?: 'view' | 'edit';
  canUserEditDevice?: boolean;
  serialNumber: string;
}

export const DeviceOperationalLifeCycleRemarks = ({
  defaultValues,
  setOpenDeviceOperationalLifeCycleRemarks,
  mode,
  startMode,
  canUserEditDevice,
  serialNumber,
  ...props
}: DeviceOperationalLifeCycleRemarksProps): JSX.Element => {
  const { t } = useTranslation();
  const { sendMessageToSnackbar } = useEnqueueSnackbar();
  const [modifyDevice, { loading }] = useMutation(MUTATION_MODIFY_DEVICE, {
    context: { timeout: graphqlApiConfig.mutationTimeout },
    onError: (error) => {
      sendMessageToSnackbar(t('forms.snackbarMessages.errorSave'), undefined, error.message || error.name, 'error');
    }
  });
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isDirty },
    getValues,
    reset
  } = useForm<DeviceOperationalLifeCycleRemarksEditRequest>({
    resolver: zodResolver(deviceOperationalLifeCycleRemarksEditSchema),
    defaultValues
  });

  const operationalLifeCycleValues = Object.values(OperationalLifeCycle);
  const operationalLifeCycleSelectOptions: RSSelectItemProps[] = operationalLifeCycleValues.map((item) => ({
    displayName: mapOperationalLifeCycleDisplayLabel(item),
    menuItemProps: { value: item }
  }));

  const handleLeave = (): void => {
    if (mode === 'edit') {
      reset();
    }

    setShowConfirmationModal(false);
    if (startMode === 'view' && mode === 'edit') {
      setOpenDeviceOperationalLifeCycleRemarks({ isOpen: true, mode: 'view', startMode });
    } else {
      setOpenDeviceOperationalLifeCycleRemarks({ isOpen: false });
    }
  };

  const handleCancel = (): void => {
    const formHasChanges = !isEqual(getValues(), defaultValues);
    if (formHasChanges) {
      setShowConfirmationModal(true);
    } else {
      if (startMode === 'view') {
        setOpenDeviceOperationalLifeCycleRemarks({ isOpen: true, mode: 'view', startMode });
      } else {
        setOpenDeviceOperationalLifeCycleRemarks({ isOpen: false });
      }
    }
  };

  const onSubmit: SubmitHandler<DeviceOperationalLifeCycleRemarksEditRequest> = (data) => {
    if (!isDirty) {
      setOpenDeviceOperationalLifeCycleRemarks({ isOpen: false });
      setShowConfirmationModal(false);
      return;
    }

    modifyDevice({
      variables: data,
      onCompleted: () => {
        sendMessageToSnackbar(t('forms.snackbarMessages.successfullySaved'), undefined, undefined, 'success');
        if (startMode === 'view') {
          setOpenDeviceOperationalLifeCycleRemarks({ isOpen: true, mode: 'view', startMode });
        } else {
          setOpenDeviceOperationalLifeCycleRemarks({ isOpen: false });
        }
        setShowConfirmationModal(false);
        reset(data);
      }
    });
  };

  const handleEditFormClose: RSDrawerProps['onClose'] = (_event, reason): void => {
    if (reason === 'escapeKeyDown') {
      handleCancel();
    }
  };

  return (
    <RSDrawer
      {...props}
      className="device-operational-life-cycle-remarks"
      onClose={
        mode === 'view' ? () => setOpenDeviceOperationalLifeCycleRemarks({ isOpen: false }) : handleEditFormClose
      }
    >
      {mode === 'edit' && canUserEditDevice ? (
        <>
          <ModalDrawerHeader
            title={t('deviceDetailsPage.operationalLifeCycleRemarksEdit.drawerTitle', {
              serialNumber: serialNumber
            })}
          />
          <form
            className="device-operational-life-cycle-remarks__form"
            onSubmit={handleSubmit(onSubmit)}
            data-testid="device-operational-life-cycle-remarks-form"
          >
            <div className="device-operational-life-cycle-remarks__input-fields">
              <Controller
                name="operationalLifeCycle"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <RSSelect
                    className="device-operational-life-cycle-remarks__operational-life-cycle-input"
                    inputLabel={t('deviceDetailsPage.operationalLifeCycleRemarksEdit.operationalLifeCycleLabel')}
                    required={true}
                    menuItems={operationalLifeCycleSelectOptions}
                    helperText={errors.operationalLifeCycle?.message}
                    error={Boolean(errors.operationalLifeCycle)}
                    onBlur={onBlur}
                    onChange={onChange}
                    data-testid="input-device-operational-life-cycle"
                    value={value}
                    disabled={loading}
                  />
                )}
              />
              <RSTextInput
                inputLabel={t('deviceDetailsPage.operationalLifeCycleRemarksEdit.remarksLabel')}
                data-testid="input-device-remarks"
                multiline={true}
                helperText={errors.remarks?.message}
                error={Boolean(errors.remarks)}
                disabled={loading}
                {...register('remarks')}
              />
            </div>
            <DrawerButtonsGroup
              handleCancel={handleCancel}
              handleSave={handleSubmit(onSubmit)}
              isSaveDisabled={loading}
              isCancelDisabled={loading}
            />
          </form>
          <ConfirmationModal
            open={showConfirmationModal}
            mainTitle={t('forms.confirmationModal.confirmModalTitle')}
            message={t('forms.confirmationModal.unsavedChangesMessage')}
            confirmButtonText={t('forms.confirmationModal.confirmActionButtonText')}
            cancelButtonText={t('forms.confirmationModal.cancelActionButtonText')}
            handleClickCancelButton={handleLeave}
            handleClickConfirmButton={() => setShowConfirmationModal(false)}
            disableConfirmButton={loading}
            disableCancelButton={loading}
          />
        </>
      ) : (
        <>
          <ModalDrawerHeader
            title={serialNumber}
            handleClose={handleLeave}
            actions={
              canUserEditDevice && (
                <EditButton
                  handleClick={() =>
                    setOpenDeviceOperationalLifeCycleRemarks({ isOpen: true, mode: 'edit', startMode })
                  }
                  data-testid="edit-device-operational-life-cycle-remarks-button"
                />
              )
            }
          />
          <div
            className="device-operational-life-cycle-remarks__view"
            data-testid="device-operational-life-cycle-remarks-view"
          >
            <InformationBlock
              label={t('deviceDetailsPage.sidePanel.mainInformation.operationalLifeCycle')}
              value={getNonNullishDisplayValue(mapOperationalLifeCycleDisplayLabel(defaultValues.operationalLifeCycle))}
            />
            <InformationBlock
              label={t('deviceDetailsPage.sidePanel.mainInformation.notes')}
              value={getNonNullishDisplayValue(defaultValues.remarks)}
            />
          </div>
        </>
      )}
    </RSDrawer>
  );
};

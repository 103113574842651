import { JSX, PropsWithChildren, useState } from 'react';

import { browserTimezone } from '../../utilities';
import {
  AutoRefreshContext,
  AutoRefreshContextInterface,
  UserTimezoneContext,
  UserTimezoneContextInterface
} from '../contexts';

export const ContextWrapper = (props: PropsWithChildren): JSX.Element => {
  const [autoRefresh, setAutoRefresh] = useState<boolean>(false);
  const autoRefreshValue: AutoRefreshContextInterface = { autoRefresh, setAutoRefresh };
  const [userTimezone, setUserTimezone] = useState<string>(browserTimezone);
  const timezoneValue: UserTimezoneContextInterface = { userTimezone, setUserTimezone };

  return (
    <AutoRefreshContext.Provider value={autoRefreshValue}>
      <UserTimezoneContext.Provider value={timezoneValue}>{props.children}</UserTimezoneContext.Provider>
    </AutoRefreshContext.Provider>
  );
};

import { useQuery } from '@apollo/client';
import { GridPaginationModel } from '@mui/x-data-grid';
import { get, isEqual, pick, toNumber, toString } from 'lodash';
import qs from 'qs';
import { JSX, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import { AddCompanyDrawer } from './add-company-drawer';
import { CompaniesAdminFilterPanel } from './companies-admin-filter-panel';
import { CompaniesAdminSearchParameters, companiesAdminStatesSchema } from './companies-admin-states-schema';
import { companiesAdminDataGridColumns, generateCompaniesAdminRows } from './data-grid-configurations';
import { companiesAdminFilterFields, generateFilterQuery, generateSortQuery } from './generate-queries';
import { CompanyBoolExp, CompanyOrderBy } from '../../../__generated__/graphql';
import { DEFAULT_GET_ADMIN_COMPANIES_SORT_BY, DEFAULT_PAGE_SIZE } from '../../../constants';
import { useAuthCheckerWithSubjectInfo } from '../../../services/authz-checker';
import { QUERY_GET_ADMIN_COMPANIES, QUERY_GET_COMPANY_NAMES } from '../../../services/queries';
import {
  calculatePaginationEndRow,
  calculatePaginationPageCount,
  calculatePaginationStartRow,
  filterValidUrlFields
} from '../../../utilities';
import { AdminOverviewMainSectionWrapper } from '../../2-templates';
import { AdminAddActionButton, AdminPageTitle, PaginationBar, RSDataGrid } from '../../4-features';
import { UserTimezoneContext } from '../../contexts';
import { ErrorPage } from '../error-page';

export const CompaniesAdminPage = (): JSX.Element => {
  const { t } = useTranslation();
  const routerLocation = useLocation();
  const { userTimezone } = useContext(UserTimezoneContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openAddCompany, setOpenAddCompany] = useState<boolean>(false);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 1, pageSize: DEFAULT_PAGE_SIZE });
  const [sortingOptions, setSortingOptions] = useState<CompanyOrderBy[]>(DEFAULT_GET_ADMIN_COMPANIES_SORT_BY);
  const [filterOptions, setFilterOptions] = useState<CompanyBoolExp>({});
  const { result: canUserCreateCompany, loading: loadingCanUserCreateCompany } = useAuthCheckerWithSubjectInfo({
    action: 'CREATE',
    subjectInfo: { type: 'Company' },
    skip: false
  });
  const { data, loading, error, refetch } = useQuery(QUERY_GET_ADMIN_COMPANIES, {
    variables: {
      offset: (paginationModel.page - 1) * DEFAULT_PAGE_SIZE,
      limit: DEFAULT_PAGE_SIZE,
      orderBy: sortingOptions,
      filters: filterOptions
    }
  });
  const {
    loading: loadingCompanyNames,
    data: dataCompanyNames,
    error: errorCompanyNames,
    refetch: refetchCompanyNames
  } = useQuery(QUERY_GET_COMPANY_NAMES, { fetchPolicy: 'network-only' });

  const searchParameters = qs.parse(searchParams.toString());
  const validUrlFields = filterValidUrlFields<CompaniesAdminSearchParameters>(
    searchParameters,
    companiesAdminStatesSchema
  );

  useEffect(() => {
    // Set page
    const pageNumber = toNumber(get(validUrlFields, 'page')) || 1;
    if (paginationModel.page !== pageNumber) {
      setPaginationModel((prevModel) => ({ ...prevModel, page: pageNumber }));
    }

    // Set sorting
    const sortParameter = get(validUrlFields, 'sort');
    const sortQuery = generateSortQuery(sortParameter);
    if (!isEqual(sortQuery, sortingOptions)) {
      setSortingOptions(sortQuery);
    }

    // Set filtering
    const filterParameters = pick(validUrlFields, companiesAdminFilterFields);
    const filterQuery = generateFilterQuery(filterParameters);
    if (!isEqual(filterQuery, filterOptions)) {
      setFilterOptions(filterQuery);
    }
  }, [routerLocation.search]);

  const groupedError = error || errorCompanyNames;
  if (groupedError) {
    return (
      <ErrorPage
        titleEmphasized={t('apolloErrorPage.errorCode')}
        title={t('apolloErrorPage.errorTitle')}
        message={groupedError.message}
      />
    );
  }

  const companiesCount = data?.companiesAggregate.aggregate?.count;
  const isLoading = loading || loadingCompanyNames || loadingCanUserCreateCompany;

  return (
    <AdminOverviewMainSectionWrapper
      title={
        <AdminPageTitle
          titleName={t('companiesAdminPage.title')}
          actions={
            <AdminAddActionButton
              titleName={t('companiesAdminPage.addCompany')}
              data-testid="companies-admin-page-add-company"
              disabled={loadingCanUserCreateCompany || !canUserCreateCompany}
              onClick={() => setOpenAddCompany(true)}
            />
          }
        />
      }
      filter={
        <CompaniesAdminFilterPanel
          isLoading={isLoading}
          companyNames={dataCompanyNames}
          defaultValues={{
            name: validUrlFields.name,
            type: validUrlFields.type,
            allowImport: validUrlFields.allowImport
          }}
        />
      }
      data-testid="companies-admin-page-wrapper"
    >
      <section className="companies-admin-page" data-testid="companies-admin-page">
        <div className="companies-admin-page__data-grid-container">
          <RSDataGrid
            columns={companiesAdminDataGridColumns}
            rows={generateCompaniesAdminRows(data?.companies, userTimezone)}
            loading={isLoading}
            data-testid="companies-admin-page-data-grid"
          />
        </div>
        <div className="companies-admin-page__pagination">
          <PaginationBar
            isLoading={isLoading}
            startRow={calculatePaginationStartRow(paginationModel.page, DEFAULT_PAGE_SIZE)}
            endRow={calculatePaginationEndRow(paginationModel.page, DEFAULT_PAGE_SIZE, companiesCount)}
            rowCount={companiesCount}
            pageCount={calculatePaginationPageCount(companiesCount)}
            onChange={(_event, page) => {
              const originalSearchParamsObject = qs.parse(searchParams.toString());
              setSearchParams(
                qs.stringify({ ...originalSearchParamsObject, page: toString(page) }, { arrayFormat: 'brackets' })
              );
            }}
            page={paginationModel.page}
          />
        </div>
      </section>
      <AddCompanyDrawer
        open={openAddCompany}
        setOpenAddCompany={setOpenAddCompany}
        refetchCompanies={refetch}
        refetchCompanyNames={refetchCompanyNames}
      />
    </AdminOverviewMainSectionWrapper>
  );
};

import { GridColDef } from '@mui/x-data-grid';

import { DeviceDeviceGroupRow } from './generate-device-group-rows';
import i18n from '../../../../i18n';

export const deviceDeviceGroupDataGridColumns: GridColDef<DeviceDeviceGroupRow>[] = [
  {
    field: 'name',
    hideable: false,
    sortable: true,
    flex: 2.4,
    minWidth: 240,
    headerClassName: 'admin-details-data-grid__data-grid-row-padding-left',
    cellClassName: 'admin-details-data-grid__data-grid-row-padding-left',
    headerName: i18n.t('deviceAdminDetailsPage.deviceGroupsDataGrid.name')
  },
  {
    field: 'serviceProvider',
    hideable: false,
    sortable: true,
    flex: 2.2,
    minWidth: 220,
    headerName: i18n.t('deviceAdminDetailsPage.deviceGroupsDataGrid.serviceProvider')
  },
  {
    field: 'devicesCount',
    hideable: false,
    sortable: true,
    flex: 1.8,
    minWidth: 180,
    headerName: i18n.t('deviceAdminDetailsPage.deviceGroupsDataGrid.devicesCount')
  },
  {
    field: 'usersCount',
    hideable: false,
    sortable: true,
    flex: 1.8,
    minWidth: 180,
    headerName: i18n.t('deviceAdminDetailsPage.deviceGroupsDataGrid.usersCount')
  },
  {
    field: 'createdAt',
    hideable: false,
    sortable: true,
    flex: 1.8,
    minWidth: 180,
    headerName: i18n.t('deviceAdminDetailsPage.deviceGroupsDataGrid.createdAt')
  },
  {
    field: 'updatedAt',
    hideable: false,
    sortable: true,
    flex: 1.8,
    minWidth: 180,
    headerClassName: 'admin-details-data-grid__data-grid-row-padding-right',
    cellClassName: 'admin-details-data-grid__data-grid-row-padding-right',
    headerName: i18n.t('deviceAdminDetailsPage.deviceGroupsDataGrid.updatedAt')
  }
];

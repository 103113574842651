import { gql } from '../../../../__generated__/gql';

export const MUTATION_REQUEST_USER_PASSWORD_RESET = gql(`
  mutation requestUserPasswordReset($email: String!) {
    requestUserPasswordReset(email: $email) {
      user {
        email
      }
    }
  }
`);

import { useQuery } from '@apollo/client';
import { GridPaginationModel } from '@mui/x-data-grid';
import { get, isEqual, pick, toNumber, toString } from 'lodash';
import qs from 'qs';
import { JSX, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import { AddUserDrawer } from './add-user-drawer';
import { generateUsersAdminRows, usersAdminDataGridColumns } from './data-grid-configurations';
import { generateFilterQuery, generateSortQuery, usersAdminFilterFields } from './generate-queries';
import { UsersAdminFilterPanel } from './users-admin-filter-panel';
import { UsersAdminSearchParameters, usersAdminStatesSchema } from './users-admin-states-schema';
import { UserBoolExp, UserOrderBy } from '../../../__generated__/graphql';
import { DEFAULT_GET_ADMIN_USERS_SORT_BY, DEFAULT_PAGE_SIZE } from '../../../constants';
import { useAuthCheckerWithSubjectInfo } from '../../../services/authz-checker';
import { QUERY_GET_ADMIN_USERS, QUERY_GET_COMPANY_NAMES, QUERY_GET_USER_NAMES } from '../../../services/queries';
import {
  calculatePaginationEndRow,
  calculatePaginationPageCount,
  calculatePaginationStartRow,
  filterValidUrlFields
} from '../../../utilities';
import { AdminOverviewMainSectionWrapper } from '../../2-templates';
import { AdminAddActionButton, AdminPageTitle, PaginationBar, RSDataGrid } from '../../4-features';
import { UserTimezoneContext } from '../../contexts';
import { ErrorPage } from '../error-page';

export const UsersAdminPage = (): JSX.Element => {
  const { t } = useTranslation();
  const routerLocation = useLocation();
  const { userTimezone } = useContext(UserTimezoneContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openAddUser, setOpenAddUser] = useState<boolean>(false);
  const [sortingOptions, setSortingOptions] = useState<UserOrderBy[]>(DEFAULT_GET_ADMIN_USERS_SORT_BY);
  const [filterOptions, setFilterOptions] = useState<UserBoolExp>({});
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 1, pageSize: DEFAULT_PAGE_SIZE });
  const { result: canUserCreateUser, loading: loadingCanUserCreateUser } = useAuthCheckerWithSubjectInfo({
    action: 'CREATE',
    subjectInfo: { type: 'User', email: '', companyId: '', isSuperUser: false, permissions: [] },
    skip: false
  });
  const { data, loading, error, refetch } = useQuery(QUERY_GET_ADMIN_USERS, {
    variables: {
      offset: (paginationModel.page - 1) * DEFAULT_PAGE_SIZE,
      limit: DEFAULT_PAGE_SIZE,
      orderBy: sortingOptions,
      filters: filterOptions
    }
  });
  const {
    loading: loadingCompanyNames,
    data: dataCompanyNames,
    error: errorCompanyNames,
    refetch: refetchCompanyNames
  } = useQuery(QUERY_GET_COMPANY_NAMES, {
    fetchPolicy: 'network-only',
    // Only get company names where there is at least one user
    variables: { filters: { users: { id: {} } } }
  });
  const {
    loading: loadingUserNames,
    data: dataUserNames,
    error: errorUserNames,
    refetch: refetchUserNames
  } = useQuery(QUERY_GET_USER_NAMES, { fetchPolicy: 'network-only' });

  const searchParameters = qs.parse(searchParams.toString());
  const validUrlFields = filterValidUrlFields<UsersAdminSearchParameters>(searchParameters, usersAdminStatesSchema);

  useEffect(() => {
    // Set page
    const pageNumber = toNumber(get(validUrlFields, 'page')) || 1;
    if (paginationModel.page !== pageNumber) {
      setPaginationModel((prevModel) => ({ ...prevModel, page: pageNumber }));
    }

    // Set sorting
    const sortParameter = get(validUrlFields, 'sort');
    const sortQuery = generateSortQuery(sortParameter);
    if (!isEqual(sortQuery, sortingOptions)) {
      setSortingOptions(sortQuery);
    }

    // Set filtering
    const filterParameters = pick(validUrlFields, usersAdminFilterFields);
    const filterQuery = generateFilterQuery(filterParameters);
    if (!isEqual(filterQuery, filterOptions)) {
      setFilterOptions(filterQuery);
    }
  }, [routerLocation.search]);

  const groupedError = error || errorCompanyNames || errorUserNames;
  if (groupedError) {
    return (
      <ErrorPage
        titleEmphasized={t('apolloErrorPage.errorCode')}
        title={t('apolloErrorPage.errorTitle')}
        message={groupedError.message}
      />
    );
  }

  const usersCount = data?.usersAggregate.aggregate?.count;

  return (
    <AdminOverviewMainSectionWrapper
      title={
        <AdminPageTitle
          titleName={t('usersAdminPage.title')}
          actions={
            <AdminAddActionButton
              titleName={t('usersAdminPage.addUser')}
              data-testid="users-admin-page-add-user"
              onClick={() => setOpenAddUser(true)}
              disabled={loadingCanUserCreateUser || !canUserCreateUser}
            />
          }
        />
      }
      filter={
        <UsersAdminFilterPanel
          isLoading={loadingCompanyNames || loadingUserNames}
          defaultValues={{
            name: validUrlFields.name,
            companyName: validUrlFields.companyName,
            companyType: validUrlFields.companyType,
            userType: validUrlFields.userType,
            permissions: validUrlFields.permissions,
            superUser: validUrlFields.superUser
          }}
          userNames={dataUserNames}
          companyNames={dataCompanyNames}
        />
      }
      data-testid="users-admin-page-wrapper"
    >
      <section className="users-admin-page" data-testid="users-admin-page">
        <div className="users-admin-page__data-grid-container">
          <RSDataGrid
            columns={usersAdminDataGridColumns}
            rows={generateUsersAdminRows(data?.users, userTimezone)}
            loading={loading}
            data-testid="users-admin-page-data-grid"
          />
        </div>
        <div className="users-admin-page__pagination">
          <PaginationBar
            isLoading={loading}
            startRow={calculatePaginationStartRow(paginationModel.page, DEFAULT_PAGE_SIZE)}
            endRow={calculatePaginationEndRow(paginationModel.page, DEFAULT_PAGE_SIZE, usersCount)}
            rowCount={usersCount}
            pageCount={calculatePaginationPageCount(usersCount)}
            onChange={(_event, page) => {
              const originalSearchParamsObject = qs.parse(searchParams.toString());
              setSearchParams(
                qs.stringify({ ...originalSearchParamsObject, page: toString(page) }, { arrayFormat: 'brackets' })
              );
            }}
            page={paginationModel.page}
          />
        </div>
      </section>
      <AddUserDrawer
        setOpenAddUser={setOpenAddUser}
        open={openAddUser}
        refetchUsers={refetch}
        refetchCompanyNames={refetchCompanyNames}
        refetchUserNames={refetchUserNames}
      />
    </AdminOverviewMainSectionWrapper>
  );
};

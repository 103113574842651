import { useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { isEmpty, isEqual } from 'lodash';
import { JSX, SetStateAction, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DeviceDetailsEditRequest, deviceDetailsEditSchema } from './validation-schema';
import {
  GetConnectorHolderTypesQuery,
  GetDeviceTypesQuery,
  ModifyDeviceMutation
} from '../../../../__generated__/graphql';
import { graphqlApiConfig } from '../../../../configs';
import { MUTATION_MODIFY_DEVICE } from '../../../../services/mutations';
import { QUERY_GET_CONNECTOR_HOLDER_TYPES, QUERY_GET_DEVICE_TYPES } from '../../../../services/queries';
import { RSDrawer, RSDrawerProps } from '../../../3-sections';
import { ConfirmationModal, DrawerButtonsGroup, ModalDrawerHeader } from '../../../4-features';
import { Loading, RSSelect, RSSelectItemProps, RSTextInput } from '../../../5-elements';
import { useEnqueueSnackbar } from '../../../hooks';

interface DeviceDetailsEditProps extends Omit<RSDrawerProps, 'children'> {
  defaultValues: DeviceDetailsEditRequest;
  setOpenDeviceDetailsEdit: (value: SetStateAction<boolean>) => void;
  siteTimezone: string;
}

export const DeviceDetailsEdit = ({
  defaultValues,
  setOpenDeviceDetailsEdit,
  siteTimezone,
  ...props
}: DeviceDetailsEditProps): JSX.Element => {
  const { t } = useTranslation();
  const { sendMessageToSnackbar } = useEnqueueSnackbar();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const {
    loading: loadingDeviceTypes,
    error: errorDeviceTypes,
    data: dataDeviceTypes
  } = useQuery(QUERY_GET_DEVICE_TYPES, { skip: !props.open });
  const {
    loading: loadingConnectorHolderTypes,
    error: errorConnectorHolderTypes,
    data: dataConnectorHolderTypes
  } = useQuery(QUERY_GET_CONNECTOR_HOLDER_TYPES, { skip: !props.open });
  const [modifyDevice, { loading }] = useMutation(MUTATION_MODIFY_DEVICE, {
    context: { timeout: graphqlApiConfig.mutationTimeout },
    onError: (error) => {
      sendMessageToSnackbar(t('forms.snackbarMessages.errorSave'), undefined, error.message || error.name, 'error');
    }
  });
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    reset
  } = useForm<DeviceDetailsEditRequest>({
    resolver: zodResolver(deviceDetailsEditSchema),
    defaultValues
  });

  const handleLeave = (): void => {
    reset();
    setShowConfirmationModal(false);
    setOpenDeviceDetailsEdit(false);
  };

  const checkNullOrNaN = (value?: null | number) => {
    if (value === null || Number.isNaN(value)) {
      return true;
    }
    return false;
  };

  const handleCancel = (): void => {
    const valueForValidation = { ...getValues() };
    const locationValueForValidation =
      checkNullOrNaN(valueForValidation.location?.latitude) && checkNullOrNaN(valueForValidation.location?.longitude)
        ? null
        : valueForValidation.location;

    const formHasChanges = !isEqual({ ...valueForValidation, location: locationValueForValidation }, defaultValues);
    if (formHasChanges) {
      setShowConfirmationModal(true);
    } else {
      setOpenDeviceDetailsEdit(false);
    }
  };

  const handleEditFormClose: RSDrawerProps['onClose'] = (_event, reason): void => {
    if (reason === 'escapeKeyDown') {
      handleCancel();
    }
  };

  const onSubmit: SubmitHandler<DeviceDetailsEditRequest> = (data) => {
    const formHasChanges = !isEqual(getValues(), defaultValues);
    if (!formHasChanges) {
      setOpenDeviceDetailsEdit(false);
      setShowConfirmationModal(false);
      return;
    }

    modifyDevice({
      variables: data as ModifyDeviceMutation['modifyDevice'],
      onCompleted: () => {
        sendMessageToSnackbar(t('forms.snackbarMessages.successfullySaved'), undefined, undefined, 'success');
        setOpenDeviceDetailsEdit(false);
        setShowConfirmationModal(false);
        reset(data);
      }
    });
  };

  const generateDeviceTypeSelectOptions = (
    deviceTypesData: GetDeviceTypesQuery['deviceTypes']
  ): RSSelectItemProps[] => {
    return deviceTypesData.map((deviceType) => ({
      displayName: deviceType.name,
      menuItemProps: { value: deviceType.id }
    }));
  };

  const generateConnectorHolderTypeOptions = (
    connectorHolderTypesData: GetConnectorHolderTypesQuery['connectorHolderTypes'],
    isConnectorTypeLabel?: boolean
  ): RSSelectItemProps[] => {
    return connectorHolderTypesData.map((connectorHolderType) => ({
      displayName: isConnectorTypeLabel ? connectorHolderType.connectorType : connectorHolderType.name,
      menuItemProps: { value: connectorHolderType.id }
    }));
  };

  const queryLoading = loadingDeviceTypes || loadingConnectorHolderTypes;
  const queryErrors = errorDeviceTypes || errorConnectorHolderTypes;

  // If errors occur during fetching the select options, enqueue an error message to the snackbar and close the drawer
  if (queryErrors) {
    sendMessageToSnackbar(
      t('forms.snackbarMessages.errorLoadingOptions'),
      undefined,
      queryErrors.message || queryErrors.name,
      'error'
    );
    setOpenDeviceDetailsEdit(false);
    return <></>;
  }

  return (
    <RSDrawer {...props} className="device-details-edit" onClose={handleEditFormClose}>
      <ModalDrawerHeader title={t('deviceDetailsPage.detailsEdit.title')} />
      {queryLoading && (
        <div className="device-details-edit__loading">
          <Loading />
        </div>
      )}
      {dataDeviceTypes && dataConnectorHolderTypes && !queryErrors && (
        <>
          <form
            className="device-details-edit__form"
            data-testid="device-details-edit-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="device-details-edit__input-fields">
              <div className="device-details-edit__single-line-inputs">
                <Controller
                  name="deviceTypeId"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <RSSelect
                      className="device-details-edit__device-type-id-input"
                      inputLabel={t('deviceDetailsPage.detailsEdit.inputLabels.deviceType')}
                      required={true}
                      menuItems={generateDeviceTypeSelectOptions(dataDeviceTypes.deviceTypes)}
                      helperText={errors.deviceTypeId?.message}
                      error={Boolean(errors.deviceTypeId)}
                      onBlur={onBlur}
                      onChange={onChange}
                      data-testid="input-device-type-id"
                      value={value}
                      disabled={loading}
                    />
                  )}
                />
                <Controller
                  name="connectorHolderTypeId"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <RSSelect
                      className="device-details-edit__connector-holder-type-input"
                      inputLabel={t('deviceDetailsPage.detailsEdit.inputLabels.connectorHolderType')}
                      menuItems={generateConnectorHolderTypeOptions(dataConnectorHolderTypes.connectorHolderTypes)}
                      helperText={errors.connectorHolderTypeId?.message}
                      error={Boolean(errors.connectorHolderTypeId)}
                      onBlur={onBlur}
                      onChange={onChange}
                      data-testid="input-device-connector-holder-type-id"
                      value={value || ''}
                      disabled={loading}
                    />
                  )}
                />
                <Controller
                  name="connectorHolderTypeId"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <RSSelect
                      className="device-details-edit__connector-holder-type-display"
                      inputLabel={t('deviceDetailsPage.detailsEdit.inputLabels.connectorType')}
                      menuItems={generateConnectorHolderTypeOptions(
                        dataConnectorHolderTypes.connectorHolderTypes,
                        true
                      )}
                      helperText={t('deviceDetailsPage.detailsEdit.inputHelperText.connectorType')}
                      error={Boolean(errors.connectorHolderTypeId)}
                      onBlur={onBlur}
                      onChange={onChange}
                      data-testid="input-device-connector-type-display"
                      value={value || ''}
                      disabled={true}
                      displayEmpty={true}
                    />
                  )}
                />
                <RSSelect
                  className="device-details-edit__site-timezone-display"
                  inputLabel={t('deviceDetailsPage.detailsEdit.inputLabels.siteTimezone')}
                  menuItems={[{ displayName: siteTimezone, menuItemProps: { value: siteTimezone } }]}
                  data-testid="input-device-site-timezone-display"
                  helperText={t('deviceDetailsPage.detailsEdit.inputHelperText.siteTimezone')}
                  value={siteTimezone}
                  disabled={true}
                />
                <RSTextInput
                  inputLabel={t('deviceDetailsPage.detailsEdit.inputLabels.latitude')}
                  type="number"
                  helperText={errors.location?.latitude?.message}
                  error={Boolean(errors.location?.latitude)}
                  data-testid="input-device-latitude"
                  {...register('location.latitude', { valueAsNumber: true })}
                />
                <RSTextInput
                  inputLabel={t('deviceDetailsPage.detailsEdit.inputLabels.longitude')}
                  type="number"
                  helperText={errors.location?.longitude?.message}
                  error={Boolean(errors.location?.longitude)}
                  data-testid="input-device-longitude"
                  {...register('location.longitude', { valueAsNumber: true })}
                />
              </div>
              <RSTextInput
                inputLabel={t('deviceDetailsPage.detailsEdit.inputLabels.description')}
                data-testid="input-device-description"
                multiline={true}
                helperText={errors.description?.message}
                error={Boolean(errors.description)}
                disabled={loading}
                {...register('description')}
              />
            </div>
            <DrawerButtonsGroup
              handleCancel={handleCancel}
              handleSave={handleSubmit(onSubmit)}
              isSaveDisabled={loading || !isEmpty(errors)}
              isCancelDisabled={loading}
            />
          </form>
          <ConfirmationModal
            open={showConfirmationModal}
            mainTitle={t('forms.confirmationModal.confirmModalTitle')}
            message={t('forms.confirmationModal.unsavedChangesMessage')}
            confirmButtonText={t('forms.confirmationModal.confirmActionButtonText')}
            cancelButtonText={t('forms.confirmationModal.cancelActionButtonText')}
            handleClickCancelButton={handleLeave}
            handleClickConfirmButton={() => setShowConfirmationModal(false)}
            disableConfirmButton={loading}
            disableCancelButton={loading}
          />
        </>
      )}
    </RSDrawer>
  );
};

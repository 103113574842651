import { isEmpty } from 'lodash';

import { GetAdminCompanyDetailsQuery } from '../../../../__generated__/graphql';
import { CompanyType, Flatten } from '../../../../types';
import { formatTimestamp, getNonNullishDisplayValue } from '../../../../utilities';

type CustomerDeviceGroups = NonNullable<
  NonNullable<GetAdminCompanyDetailsQuery['companyByPK']>['customer']
>['deviceGroups'];
type ServiceProviderDeviceGroups = NonNullable<
  NonNullable<GetAdminCompanyDetailsQuery['companyByPK']>['serviceProvider']
>['deviceGroups'];

export const generateDeviceGroupRows = (
  companyType: CompanyType,
  deviceGroups: CustomerDeviceGroups | ServiceProviderDeviceGroups,
  userTimezone?: string
) => {
  if (isEmpty(deviceGroups)) {
    return [];
  }

  if (companyType === CompanyType.Customer) {
    return (deviceGroups as CustomerDeviceGroups).map((deviceGroup) => ({
      id: deviceGroup.id,
      name: deviceGroup.name,
      serviceProvider: getNonNullishDisplayValue(deviceGroup.serviceProvider?.company.name),
      deviceCount: deviceGroup.deviceGroupDevicesAggregate.aggregate?.count || 0,
      userCount: deviceGroup.userDeviceGroupsAggregate.aggregate?.count || 0,
      createdAt: formatTimestamp(deviceGroup.createdAt, userTimezone),
      updatedAt: formatTimestamp(deviceGroup.updatedAt, userTimezone)
    }));
  }

  if (companyType === CompanyType.ServiceProvider) {
    return (deviceGroups as ServiceProviderDeviceGroups).map((deviceGroup) => ({
      id: deviceGroup.id,
      name: deviceGroup.name,
      customer: getNonNullishDisplayValue(deviceGroup.customer?.company.name),
      deviceCount: deviceGroup.deviceGroupDevicesAggregate.aggregate?.count || 0,
      userCount: deviceGroup.userDeviceGroupsAggregate.aggregate?.count || 0,
      createdAt: formatTimestamp(deviceGroup.createdAt, userTimezone),
      updatedAt: formatTimestamp(deviceGroup.updatedAt, userTimezone)
    }));
  }

  return [];
};

export type CompanyDeviceGroupRow = Flatten<ReturnType<typeof generateDeviceGroupRows>>;

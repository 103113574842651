import { gql } from '../../../../__generated__/gql';

export const QUERY_GET_ADMIN_DEVICE_GROUP_DETAILS = gql(`
  query getAdminDeviceGroupDetails($deviceGroupId: uuid!) {
    deviceGroupByPK(id: $deviceGroupId) {
      id
      name
      description
      createdAt
      updatedAt
      customer {
        companyId
        company {
          id
          name
        }
      }
      serviceProvider {
        companyId
        company {
          id
          name
        }
      }
      deviceGroupDevices {
        deviceId
        createdAt
        updatedAt
        device {
          id
          serialNumber
          deviceType {
            id
            modelNumber
          }
          connectorHolderType {
            id
            name
          }
          program {
            id
            name
          }
        }
      }
      userDeviceGroups {
        id
        createdAt
        updatedAt
        validUntil
        userRole
        user {
          id
          firstName
          lastName
          company {
            id
            name
            companyType
          }
        }
      }
    }
  }
`);

import { isEmpty } from 'lodash';

import { GetAdminDeviceGroupDetailsQuery, UserDeviceGroupRole } from '../../../../__generated__/graphql';
import { CompanyType, Flatten } from '../../../../types';
import {
  formatTimestamp,
  getNonNullishDisplayValue,
  mapCompanyType,
  mapUserDeviceGroupRole
} from '../../../../utilities';

export const generateUserRows = (
  users: NonNullable<GetAdminDeviceGroupDetailsQuery['deviceGroupByPK']>['userDeviceGroups'],
  userTimezone?: string
) => {
  if (isEmpty(users)) {
    return [];
  }

  return users.map((user) => ({
    id: user.id,
    name: `${user.user.firstName} ${user.user.lastName}`,
    companyName: user.user.company.name,
    companyType: mapCompanyType(user.user.company.companyType as CompanyType),
    deviceGroupRole: mapUserDeviceGroupRole(user.userRole as UserDeviceGroupRole),
    validUntil: user.validUntil
      ? formatTimestamp(user.validUntil, userTimezone)
      : getNonNullishDisplayValue(user.validUntil),
    createdAt: formatTimestamp(user.createdAt, userTimezone),
    updatedAt: formatTimestamp(user.updatedAt, userTimezone)
  }));
};

export type DeviceGroupUserRow = Flatten<ReturnType<typeof generateUserRows>>;

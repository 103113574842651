import { GridColDef } from '@mui/x-data-grid';

import { DeviceGroupDeviceRow } from './generate-device-rows';
import i18n from '../../../../i18n';

export const deviceGroupDevicesDataGridColumns: GridColDef<DeviceGroupDeviceRow>[] = [
  {
    field: 'serialNumber',
    hideable: false,
    sortable: true,
    flex: 1.7,
    minWidth: 170,
    headerClassName: 'admin-details-data-grid__data-grid-row-padding-left',
    cellClassName: 'admin-details-data-grid__data-grid-row-padding-left',
    headerName: i18n.t('deviceGroupDetailsPage.deviceDataGrid.serialNumber')
  },
  {
    field: 'program',
    hideable: false,
    sortable: true,
    flex: 1.8,
    minWidth: 180,
    headerName: i18n.t('deviceGroupDetailsPage.deviceDataGrid.program')
  },
  {
    field: 'type',
    hideable: false,
    sortable: true,
    flex: 1.8,
    minWidth: 180,
    headerName: i18n.t('deviceGroupDetailsPage.deviceDataGrid.type')
  },
  {
    field: 'connectorHolderType',
    hideable: false,
    sortable: true,
    flex: 2.2,
    minWidth: 220,
    headerName: i18n.t('deviceGroupDetailsPage.deviceDataGrid.connectorHolderType')
  },
  {
    field: 'createdAt',
    hideable: false,
    sortable: true,
    flex: 1.8,
    minWidth: 180,
    headerName: i18n.t('deviceGroupDetailsPage.deviceDataGrid.createdAt')
  },
  {
    field: 'updatedAt',
    hideable: false,
    sortable: true,
    flex: 1.8,
    minWidth: 180,
    headerClassName: 'admin-details-data-grid__data-grid-row-padding-right',
    cellClassName: 'admin-details-data-grid__data-grid-row-padding-right',
    headerName: i18n.t('deviceGroupDetailsPage.deviceDataGrid.updatedAt')
  }
];

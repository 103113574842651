import { isEmpty } from 'lodash';

import { GetAdminDeviceDetailsQuery } from '../../../../__generated__/graphql';
import { Flatten } from '../../../../types';
import { formatTimestamp, getNonNullishDisplayValue } from '../../../../utilities';

export const generateDeviceGroupRows = (
  deviceGroupDevices: NonNullable<GetAdminDeviceDetailsQuery['deviceByPK']>['deviceGroupDevices'],
  userTimezone?: string
) => {
  if (isEmpty(deviceGroupDevices)) {
    return [];
  }

  return deviceGroupDevices.map((deviceGroupDevice) => ({
    id: deviceGroupDevice.deviceGroup.id,
    name: deviceGroupDevice.deviceGroup.name,
    serviceProvider: getNonNullishDisplayValue(deviceGroupDevice.deviceGroup.serviceProvider?.company.name),
    devicesCount: deviceGroupDevice.deviceGroup.deviceGroupDevicesAggregate.aggregate?.count || 0,
    usersCount: deviceGroupDevice.deviceGroup.userDeviceGroupsAggregate.aggregate?.count || 0,
    createdAt: formatTimestamp(deviceGroupDevice.createdAt, userTimezone),
    updatedAt: formatTimestamp(deviceGroupDevice.updatedAt, userTimezone)
  }));
};

export type DeviceDeviceGroupRow = Flatten<ReturnType<typeof generateDeviceGroupRows>>;

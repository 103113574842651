import { Box, Modal, ModalProps } from '@mui/material';
import { MouseEventHandler } from 'react';

import { RSButton, RSButtonProps } from '../../5-elements';

/**
 * This component renders a ConfirmationModal component. It is based on the MUI Modal and the MUI Box components.
 * The API is the same as the MUI Modal component: https://mui.com/material-ui/api/modal/
 * NOTE: If you want to prevent closing the modal when using the escape key or click on the backdrop, please DO NOT
 * specify `onClose` property when using this component.
 *
 * @param {boolean} open Whether to open the modal. Usually a controlled value with React state management is used.
 * @param {string} [mainTitle] The main title of the modal pop-up dialog window.
 * @param {string} [message] The message of the modal pop-up dialog window.
 * @param {string} [primaryButtonText] The text on the primary button.
 * @param {string} [secondaryButtonText] The text on the secondary button.
 * @param {RSButtonProps['color']} [primaryButtonColor] The color of the primary button. The interface is the same as
 *   `color` from RSButtonProps.
 * @param {MouseEventHandler<HTMLButtonElement>} [handleClickPrimaryButton] The action for the primary button.
 * @param {MouseEventHandler<HTMLButtonElement>} [handleClickSecondaryButton] The action for the secondary button.
 * @returns {React.JSX.Element} A React element that renders a ConfirmationModal component.
 */
interface ConfirmationModalProps extends Omit<ModalProps, 'children'> {
  mainTitle?: string;
  message?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmButtonColor?: RSButtonProps['color'];
  cancelButtonColor?: RSButtonProps['color'];
  handleClickConfirmButton?: MouseEventHandler<HTMLButtonElement>;
  handleClickCancelButton?: MouseEventHandler<HTMLButtonElement>;
  disableCancelButton?: boolean;
  disableConfirmButton?: boolean;
}

export const ConfirmationModal = ({
  mainTitle,
  message,
  confirmButtonColor,
  cancelButtonColor,
  confirmButtonText,
  cancelButtonText,
  handleClickCancelButton,
  handleClickConfirmButton,
  disableCancelButton,
  disableConfirmButton,
  ...props
}: ConfirmationModalProps): JSX.Element => {
  return (
    <Modal {...props} className="confirmation-modal" data-testid="confirmation-modal">
      <Box className="confirmation-modal__box">
        <div className="confirmation-modal__container" data-testid="confirmation-modal-container">
          <div className="confirmation-modal__main-contents">
            {mainTitle && <h3 className="confirmation-modal__main-title">{mainTitle}</h3>}
            {message && <p className="confirmation-modal__message">{message}</p>}
          </div>
          <div className="confirmation-modal__button-group">
            <RSButton
              data-testid="confirmation-modal-cancel-button"
              onClick={handleClickCancelButton}
              disabled={disableCancelButton}
              color={cancelButtonColor || 'primary'}
            >
              {cancelButtonText}
            </RSButton>
            <RSButton
              data-testid="confirmation-modal-confirm-button"
              variant="contained"
              color={confirmButtonColor || 'primary'}
              onClick={handleClickConfirmButton}
              disabled={disableConfirmButton}
            >
              {confirmButtonText}
            </RSButton>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

import { isEmpty } from 'lodash';

import { GetAdminCompanyDetailsQuery } from '../../../../__generated__/graphql';
import { Flatten } from '../../../../types';
import { formatTimestamp, getNonNullishDisplayValue } from '../../../../utilities';

export const generateSiteRows = (
  sites: NonNullable<NonNullable<GetAdminCompanyDetailsQuery['companyByPK']>['customer']>['sites'],
  userTimezone?: string
) => {
  if (isEmpty(sites)) {
    return [];
  }

  return sites.map((site) => ({
    id: site.id,
    name: site.name,
    timezone: site.timezoneName,
    address: getNonNullishDisplayValue(site.location.formattedAddress),
    createdAt: formatTimestamp(site.createdAt, userTimezone),
    updatedAt: formatTimestamp(site.updatedAt, userTimezone)
  }));
};

export type CompanySiteRow = Flatten<ReturnType<typeof generateSiteRows>>;

import { gql } from '../../../../__generated__/gql';

export const QUERY_GET_USER_PERMISSION_RIGHTS = gql(`
  query getUserPermissionRights {
    userPermissionRight {
      permission
      forServiceProvider
      forCustomer
    }
  }
`);

import { gql } from '../../../../__generated__/gql';

export const MUTATION_CREATE_USER = gql(`
  mutation createUser(
    $firstName: String!
    $lastName: String!
    $isSuperUser: Boolean!
    $companyId: String!
    $email: String!
    $userType: UserType!
    $permissions: [UserPermissionType!]!
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      isSuperUser: $isSuperUser
      companyId: $companyId
      email: $email
      userType: $userType
      permissions: $permissions
      createOnly: true
    ) {
      id
      user {
        id
        firstName
        lastName
        email
        isSuperUser
        company {
          id
          name
          companyType
        }
        userType
        userPermissionLinks {
          id
          userPermission
        }
      }
    }
  }
`);

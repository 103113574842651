import { GridColDef } from '@mui/x-data-grid';

import { DeviceGroupUserRow } from './generate-user-rows';
import i18n from '../../../../i18n';

export const deviceGroupUsersDataGridColumns: GridColDef<DeviceGroupUserRow>[] = [
  {
    field: 'name',
    hideable: false,
    sortable: true,
    flex: 1.7,
    minWidth: 170,
    headerClassName: 'admin-details-data-grid__data-grid-row-padding-left',
    cellClassName: 'admin-details-data-grid__data-grid-row-padding-left',
    headerName: i18n.t('deviceGroupDetailsPage.userDataGrid.name')
  },
  {
    field: 'companyName',
    hideable: false,
    sortable: true,
    flex: 2,
    minWidth: 200,
    headerName: i18n.t('deviceGroupDetailsPage.userDataGrid.companyName')
  },
  {
    field: 'companyType',
    hideable: false,
    sortable: true,
    flex: 1.6,
    minWidth: 160,
    headerName: i18n.t('deviceGroupDetailsPage.userDataGrid.companyType')
  },
  {
    field: 'deviceGroupRole',
    hideable: false,
    sortable: true,
    flex: 1.7,
    minWidth: 170,
    headerName: i18n.t('deviceGroupDetailsPage.userDataGrid.deviceGroupRole')
  },
  {
    field: 'validUntil',
    hideable: false,
    sortable: true,
    flex: 1.8,
    minWidth: 180,
    headerName: i18n.t('deviceGroupDetailsPage.userDataGrid.validUntil')
  },
  {
    field: 'createdAt',
    hideable: false,
    sortable: true,
    flex: 1.8,
    minWidth: 180,
    headerName: i18n.t('deviceGroupDetailsPage.userDataGrid.createdAt')
  },
  {
    field: 'updatedAt',
    hideable: false,
    sortable: true,
    flex: 1.8,
    minWidth: 180,
    headerClassName: 'admin-details-data-grid__data-grid-row-padding-right',
    cellClassName: 'admin-details-data-grid__data-grid-row-padding-right',
    headerName: i18n.t('deviceGroupDetailsPage.userDataGrid.updatedAt')
  }
];

import { faker } from '@faker-js/faker';

import { GetDevicesQuery, OperationalLifeCycle } from '../../../__generated__/graphql';
import { DeviceState } from '../../../types';

// Generate 90 items for pagination test
const fakerGeneratedDevices = (): GetDevicesQuery['devices'] => {
  const devices: GetDevicesQuery['devices'] = [];
  for (let i = 0; i < 90; i += 1) {
    const companyId = faker.string.uuid();
    const deviceId = faker.string.uuid();
    devices.push({
      id: deviceId,
      serialNumber: faker.string.alphanumeric(10),
      deviceCurrentPerformance: {
        deviceId: deviceId,
        performanceDiff: faker.number.int(100),
        performanceLastPeriodPct: faker.number.int(100),
        totalDiffPct: faker.number.int(100),
        totalLastPeriod: faker.number.int(100)
      },
      deviceLastState: {
        id: faker.string.uuid(),
        deviceStatus: faker.helpers.enumValue(DeviceState) as unknown as string,
        isActive: true,
        stateName: faker.helpers.enumValue(DeviceState) as unknown as string,
        updatedAt: faker.date.past().toISOString()
      },
      deviceMeasurementValues: [
        {
          id: faker.string.uuid(),
          value: 2,
          updatedAt: faker.date.past().toISOString(),
          status: 'OK',
          deviceMeasurementProperty: {
            id: faker.string.uuid(),
            name: 'is_online',
            unit: 'ONLINE'
          }
        }
      ],
      operationalLifeCycle: faker.helpers.enumValue(OperationalLifeCycle),
      deviceSoftwareConfigurationActive: {
        id: faker.string.uuid(),
        rocosComposedVersion: faker.string.alpha(20)
      },
      connectorHolderType: {
        id: faker.string.uuid(),
        connectorType: faker.string.alphanumeric(5)
      },
      site: {
        id: faker.string.uuid(),
        name: faker.string.alpha(10),
        customer: {
          companyId: companyId,
          company: {
            id: companyId,
            name: faker.string.alpha(10)
          }
        }
      },
      program: {
        id: faker.string.uuid(),
        name: `${faker.number.int({ min: 100000000, max: 999999999 })}-${faker.number.int({ min: 1, max: 9 })}`
      }
    });
  }
  return devices;
};

export const fakerGeneratedDevicesResponse: GetDevicesQuery = {
  devicesAggregate: { aggregate: { count: 90 } },
  devices: fakerGeneratedDevices()
};

export const getDevicesQueryResponse: GetDevicesQuery = {
  devicesAggregate: { aggregate: { count: 4 } },
  devices: [
    {
      id: 'd8295dbe-34df-47a7-88c0-71e7520d787d',
      serialNumber: 'ROC00000001',
      deviceCurrentPerformance: {
        deviceId: 'd8295dbe-34df-47a7-88c0-71e7520d787d',
        performanceDiff: (20 / 60) * 100 - (10 / 40) * 100,
        performanceLastPeriodPct: (20 / 60) * 100,
        totalDiffPct: ((60 - 40) / 40) * 100,
        totalLastPeriod: 60
      },
      deviceLastState: {
        id: '7920b6fa-ca40-416a-9719-0ee9f3cc8da6',
        deviceStatus: 'Operational',
        isActive: true,
        stateName: 'Operational',
        updatedAt: '2024-10-04T11:41:06.790Z'
      },
      deviceMeasurementValues: [
        {
          id: 'd6e05746-8428-4cad-9db2-3cfb02800196',
          value: 2,
          updatedAt: '2024-10-04T11:41:06.790Z',
          status: 'OK',
          deviceMeasurementProperty: {
            id: '31fa7634-aa8b-4de8-9f78-e9f6084477d8',
            name: 'is_online',
            unit: 'ONLINE'
          }
        }
      ],
      operationalLifeCycle: 'OPERATIONAL',
      deviceSoftwareConfigurationActive: {
        id: '73f74cda-bfa6-4585-a32d-1f06f3fd6d91',
        rocosComposedVersion: 'cloud-fake-command-server-release@75d98a'
      },
      connectorHolderType: {
        id: '5b066fb4-1a80-4b1e-88c9-f79d6278edfb',
        connectorType: 'CCS-2'
      },
      site: {
        id: 'bfb9188e-ba46-45f3-978d-74140a515259',
        name: 'Rocsys Robert Home',
        customer: {
          companyId: 'dd10f31d-1cd5-4a7f-90aa-1bdc1e5968a3',
          company: {
            id: 'dd10f31d-1cd5-4a7f-90aa-1bdc1e5968a3',
            name: 'Rocsys Production Factory'
          }
        }
      },
      program: {
        id: 'b4978b7f-8f23-4523-9b2b-0830c08fefea',
        name: '202372967-1'
      }
    },
    {
      id: '7b0cb031-62d3-4edc-b5ce-cdf16f592a8a',
      serialNumber: 'ROC00000401',
      deviceCurrentPerformance: {
        deviceId: '7b0cb031-62d3-4edc-b5ce-cdf16f592a8a',
        performanceDiff: (30 / 40) * 100 - (40 / 60) * 100,
        performanceLastPeriodPct: (30 / 40) * 100,
        totalDiffPct: ((40 - 60) / 60) * 100,
        totalLastPeriod: 40
      },
      deviceLastState: {
        id: '7920b6fa-ca40-416a-9719-0ee9f3cc8da6',
        deviceStatus: 'Operational',
        isActive: true,
        stateName: 'Operational',
        updatedAt: '2024-10-04T11:41:06.790Z'
      },
      deviceMeasurementValues: [
        {
          id: 'd6e05746-8428-4cad-9db2-3cfb02800196',
          value: 2,
          updatedAt: '2024-10-04T11:41:06.790Z',
          status: 'OK',
          deviceMeasurementProperty: {
            id: '31fa7634-aa8b-4de8-9f78-e9f6084477d8',
            name: 'is_online',
            unit: 'ONLINE'
          }
        }
      ],
      operationalLifeCycle: 'OPERATIONAL',
      deviceSoftwareConfigurationActive: {
        id: '9b0dda82-41a3-4bf2-85b4-67e34f7cfe3c',
        rocosComposedVersion: 'cloud-fake-command-server-release@75d98a'
      },
      connectorHolderType: {
        id: '5b066fb4-1a80-4b1e-88c9-f79d6278edfb',
        connectorType: 'CCS-2'
      },
      site: {
        id: '8dde1ecd-1f82-483b-b1af-756065b3efeb',
        name: 'Rocsys Factory',
        customer: {
          companyId: 'dd10f31d-1cd5-4a7f-90aa-1bdc1e5968a3',
          company: {
            id: 'dd10f31d-1cd5-4a7f-90aa-1bdc1e5968a3',
            name: 'Rocsys Production Factory'
          }
        }
      },
      program: {
        id: '32af6e11-d67e-421e-b6ea-52a1094ff444',
        name: '202374141-3'
      }
    },
    {
      id: '7d36b207-40b7-40bb-b943-125dc55089a1',
      serialNumber: 'ROC000000SMOKE',
      deviceCurrentPerformance: {
        deviceId: '7d36b207-40b7-40bb-b943-125dc55089a1',
        performanceDiff: (200 / 300) * 100 - (100 / 300) * 100,
        performanceLastPeriodPct: (200 / 300) * 100,
        totalDiffPct: 0,
        totalLastPeriod: 300
      },
      deviceLastState: {
        id: '7920b6fa-ca40-416a-9719-0ee9f3cc8da6',
        deviceStatus: 'Operational',
        isActive: true,
        stateName: 'Operational',
        updatedAt: '2024-10-04T11:41:06.790Z'
      },
      deviceMeasurementValues: [
        {
          id: 'd6e05746-8428-4cad-9db2-3cfb02800196',
          value: 2,
          updatedAt: '2024-10-04T11:41:06.790Z',
          status: 'OK',
          deviceMeasurementProperty: {
            id: '31fa7634-aa8b-4de8-9f78-e9f6084477d8',
            name: 'is_online',
            unit: 'ONLINE'
          }
        }
      ],
      operationalLifeCycle: 'OPERATIONAL',
      deviceSoftwareConfigurationActive: null,
      connectorHolderType: {
        id: '5b066fb4-1a80-4b1e-88c9-f79d6278edfb',
        connectorType: 'CCS-2'
      },
      site: {
        id: '7f6d2db5-fbf2-4314-9d3c-203829f82162',
        name: 'Good Ev Site1',
        customer: {
          companyId: '9fdd7294-1712-4901-94f8-5046a7764124',
          company: {
            id: '9fdd7294-1712-4901-94f8-5046a7764124',
            name: 'Good EV Comp'
          }
        }
      },
      program: {
        id: 'b4978b7f-8f23-4523-9b2b-0830c08fefea',
        name: '202372967-5'
      }
    },
    {
      id: 'bc296827-712b-4be4-8098-74e197498a1b',
      serialNumber: 'ROC00000501',
      deviceCurrentPerformance: {
        deviceId: 'bc296827-712b-4be4-8098-74e197498a1b',
        performanceDiff: (100 / 300) * 100 - (200 / 300) * 100,
        performanceLastPeriodPct: (100 / 300) * 100,
        totalDiffPct: 0,
        totalLastPeriod: 300
      },
      deviceLastState: {
        id: '7920b6fa-ca40-416a-9719-0ee9f3cc8da6',
        deviceStatus: 'Operational',
        isActive: true,
        stateName: 'Operational',
        updatedAt: '2024-10-04T11:41:06.790Z'
      },
      deviceMeasurementValues: [
        {
          id: 'd6e05746-8428-4cad-9db2-3cfb02800196',
          value: 2,
          updatedAt: '2024-10-04T11:41:06.790Z',
          status: 'OK',
          deviceMeasurementProperty: {
            id: '31fa7634-aa8b-4de8-9f78-e9f6084477d8',
            name: 'is_online',
            unit: 'ONLINE'
          }
        }
      ],
      operationalLifeCycle: 'OPERATIONAL',
      deviceSoftwareConfigurationActive: {
        id: '0ee824b7-8c1c-4078-9817-1afa0e38bf41',
        rocosComposedVersion: 'v0.24.0'
      },
      connectorHolderType: {
        id: '63c0a6de-5a77-4a13-8723-3acf3d1ba9f2',
        connectorType: 'Euro-Din'
      },
      site: {
        id: '7f6d2db5-fbf2-4314-9d3c-203829f82162',
        name: 'Good Ev Site1',
        customer: {
          companyId: '9fdd7294-1712-4901-94f8-5046a7764124',
          company: {
            id: '9fdd7294-1712-4901-94f8-5046a7764124',
            name: 'Good EV Comp'
          }
        }
      },
      program: {
        id: '32af6e11-d67e-421e-b6ea-52a1094ff444',
        name: '202374141-3'
      }
    }
  ]
};

import { gql } from '../../../../__generated__/gql';

export const QUERY_GET_ADMIN_SITE_DETAILS = gql(`
  query getAdminSiteDetails($siteId: uuid!) {
    siteByPK(id: $siteId) {
      id
      name
      customer {
        companyId
        company {
          id
          name
        }
      }
      location {
        id
        latitude
        longitude
        formattedAddress
      }
      timezoneName
      description
      createdAt
      updatedAt
      devices {
        id
        serialNumber
        deviceGroupDevicesAggregate {
          aggregate {
            count
          }
        }
        program {
          id
          name
        }
        deviceType {
          id
          modelNumber
        }
        connectorHolderType {
          id
          name
        }
        deactivated
        createdAt
        updatedAt
      }
    }
  }
`);

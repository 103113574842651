import { Dispatch, SetStateAction, createContext } from 'react';

export interface AutoRefreshContextInterface {
  autoRefresh: boolean;
  setAutoRefresh: Dispatch<SetStateAction<boolean>>;
}

export const AutoRefreshContext = createContext<AutoRefreshContextInterface>({
  autoRefresh: false,
  setAutoRefresh: () => {}
});

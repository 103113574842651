import { gql } from '../../../../__generated__/gql';

export const MUTATION_CREATE_SERVICE_PROVIDER = gql(`
  mutation createServiceProvider($name: String!, $maxDeviceGroupRole: UserDeviceGroupUserRole!) {
    createServiceProvider(name: $name, maxDeviceGroupRole: $maxDeviceGroupRole) {
      id
      serviceProvider {
        companyId
        company {
          id
          name
          maxDeviceGroupRole
        }
      }
    }
  }
`);

import { useMutation } from '@apollo/client';
import { isEmpty } from 'lodash';
import { JSX, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { GetAdminUserDetailsQuery, UserPermissionType, UserType } from '../../../../__generated__/graphql';
import { appConfig, graphqlApiConfig } from '../../../../configs';
import { useAuthCheckerWithSubjectInfo } from '../../../../services/authz-checker';
import { MUTATION_REQUEST_USER_PASSWORD_RESET } from '../../../../services/mutations';
import { CompanyType } from '../../../../types';
import {
  contextualizeBoolean,
  getNonNullishDisplayValue,
  mapCompanyType,
  mapUserPermissionType,
  mapUserType
} from '../../../../utilities';
import { AdminDetailsPanelTitle } from '../../../4-features';
import { InformationBlock, RSButton } from '../../../5-elements';
import { useEnqueueSnackbar, useFormatTimezone } from '../../../hooks';

interface UserDetailsAdminPanelProps {
  // This panel will only be shown if the user details are successfully loaded
  userDetails: NonNullable<GetAdminUserDetailsQuery['userByPK']>;
}

export const UserDetailsAdminPanel = ({ userDetails }: UserDetailsAdminPanelProps): JSX.Element => {
  const { t } = useTranslation();
  const { formatWithDefaultTimezone } = useFormatTimezone();
  const { sendMessageToSnackbar } = useEnqueueSnackbar();
  const { result: canUserModifyUser, loading: loadingCanUserModifyUser } = useAuthCheckerWithSubjectInfo({
    action: 'UPDATE',
    subjectInfo: {
      type: 'User',
      email: userDetails.email || '',
      companyId: userDetails.company.id || '',
      isSuperUser: userDetails.isSuperUser || false,
      id: userDetails.id || '',
      permissions: userDetails.userPermissionLinks.map(
        (permission) => permission.userPermission
      ) as UserPermissionType[]
    },
    skip: false
  });
  const [requestUserPasswordReset, { loading }] = useMutation(MUTATION_REQUEST_USER_PASSWORD_RESET, {
    context: { timeout: graphqlApiConfig.mutationTimeout },
    onError: (error) => {
      sendMessageToSnackbar(
        t('userAdminDetailsPage.panel.passwordResetFailed'),
        undefined,
        error.message || error.name,
        'error'
      );
    }
  });

  const handleResetPasswordClick = (event: MouseEvent) => {
    event.preventDefault();
    requestUserPasswordReset({
      variables: { email: userDetails.email },
      onCompleted: () =>
        sendMessageToSnackbar(
          t('userAdminDetailsPage.panel.passwordResetSuccess', { email: userDetails.email }),
          undefined,
          undefined,
          'success'
        )
    });
  };

  // reset not allowed for portal users with SSO enabled - the rest is ok
  // we'd rather have this knowledge in the database, but for now we'll keep it here
  const isPasswordResetAllowed = (userDetails: UserDetailsAdminPanelProps['userDetails']): boolean => {
    return !(userDetails.userType === UserType.PortalUser && userDetails.ssoConfiguration?.enabled);
  };

  return (
    <aside className="user-details-admin-panel" data-testid="user-details-admin-panel">
      <AdminDetailsPanelTitle
        objectType={t('userAdminDetailsPage.panel.type')}
        objectName={`${userDetails.firstName} ${userDetails.lastName}`}
        goBackUrl={`${appConfig.basePath}/admin/users`}
        editButtonProps={{ disabled: true }}
      />
      <div className="user-details-admin-panel__contents">
        <InformationBlock
          label={t('userAdminDetailsPage.panel.email')}
          data-testid="user-details-admin-panel-email"
          value={userDetails.email}
        />
        <InformationBlock
          label={t('userAdminDetailsPage.panel.userType')}
          data-testid="user-details-admin-panel-user-type"
          value={mapUserType(userDetails.userType as UserType)}
        />
        <InformationBlock
          label={t('userAdminDetailsPage.panel.companyType')}
          data-testid="user-details-admin-panel-company-type"
          value={mapCompanyType(userDetails.company.companyType as CompanyType)}
        />
        <InformationBlock
          label={t('userAdminDetailsPage.panel.superUser')}
          data-testid="user-details-admin-panel-super-user"
          value={contextualizeBoolean(
            t('userAdminDetailsPage.panel.superUserEnabled'),
            t('userAdminDetailsPage.panel.superUserDisabled'),
            userDetails.isSuperUser
          )}
        />
        <InformationBlock
          label={t('userAdminDetailsPage.panel.permissions')}
          data-testid="user-details-admin-panel-permissions"
          value={
            <div className="user-details-admin-panel__permissions-value">
              {isEmpty(userDetails.userPermissionLinks)
                ? getNonNullishDisplayValue(undefined)
                : userDetails.userPermissionLinks.map((permission) => (
                    <span key={permission.id}>
                      {mapUserPermissionType(permission.userPermission as UserPermissionType)}
                    </span>
                  ))}
            </div>
          }
        />
        <InformationBlock
          label={t('userAdminDetailsPage.panel.createdAt')}
          data-testid="user-details-admin-panel-created-at"
          value={formatWithDefaultTimezone(userDetails.createdAt)}
        />
        <InformationBlock
          label={t('userAdminDetailsPage.panel.updatedAt')}
          data-testid="user-details-admin-panel-updated-at"
          value={formatWithDefaultTimezone(userDetails.updatedAt)}
        />
        {canUserModifyUser && isPasswordResetAllowed(userDetails) && !loadingCanUserModifyUser && (
          <InformationBlock
            label={t('userAdminDetailsPage.panel.requestNewPassword')}
            data-testid="user-details-admin-panel-request-new-password"
            value={
              <div className="user-details-admin-panel__send-email">
                <span>{t('userAdminDetailsPage.panel.requestNewPasswordDescription')}</span>
                <RSButton
                  color="success"
                  variant="outlined"
                  extraClassNames={['user-details-admin-panel__send-email-button']}
                  onClick={handleResetPasswordClick}
                  disabled={loading}
                  data-testid="user-detail-admin-panel-send-email-button"
                >
                  {t('userAdminDetailsPage.panel.sendEmail')}
                </RSButton>
              </div>
            }
          />
        )}
      </div>
    </aside>
  );
};

import { zodResolver } from '@hookform/resolvers/zod';
import { filter, includes, isEqual, omit, pick, uniq } from 'lodash';
import { DateTime } from 'luxon';
import qs from 'qs';
import { JSX, useContext, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import { getOperationResultDisplayOption } from './operation-result-display-option';
import { OperationsFilterDatePicker } from './operations-filter-date-picker';
import {
  GetCustomerNamesQuery,
  GetOperationResultsQuery,
  GetProgramNamesQuery,
  GetSerialNumbersQuery,
  GetSiteNamesQuery,
  OperationalLifeCycle
} from '../../../../__generated__/graphql';
import InfoIcon from '../../../../assets/icons/info.svg?react';
import { AnnotationResult, DeviceDeactivated, OperationPeriod, RSAutocompleteValue } from '../../../../types';
import {
  OperationalLifeCycleType,
  browserTimezone,
  calculateTimeRangeFromPeriod,
  filterValidUrlFields,
  mapAnnotationResultDisplayLabel,
  mapOperationPeriodDisplayLabel,
  mapOperationalLifeCycleDisplayLabel,
  validateTimezone
} from '../../../../utilities';
import { FilterPanelButtonsGroup } from '../../../4-features';
import {
  RSAutocomplete,
  RSAutocompleteDefaultMenuOption,
  RSSelect,
  RSSelectItemProps,
  RSTextInput,
  RSTooltip
} from '../../../5-elements';
import { UserTimezoneContext } from '../../../contexts';
import { IN_PROGRESS_CODE, operationsFilterFields } from '../generate-queries';
import {
  OperationsFilters,
  OperationsOverviewSearchParameters,
  operationsFilterSchema,
  operationsOverviewStatesSchema
} from '../operations-overview-states-schema';

interface OperationsFilterPanelProps {
  serialNumbers?: GetSerialNumbersQuery;
  operationResults?: GetOperationResultsQuery;
  customerNames?: GetCustomerNamesQuery;
  siteNames?: GetSiteNamesQuery;
  programNames?: GetProgramNamesQuery;
  defaultValues: OperationsFilters;
  showCustomer?: boolean;
}

export const OperationsFilterPanel = ({
  serialNumbers,
  operationResults,
  customerNames,
  siteNames,
  programNames,
  defaultValues,
  showCustomer
}: OperationsFilterPanelProps): JSX.Element => {
  const { t } = useTranslation();
  const { userTimezone } = useContext(UserTimezoneContext);
  const routerLocation = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    getValues,
    setValue,
    watch,
    register
  } = useForm<OperationsFilters>({
    resolver: zodResolver(operationsFilterSchema),
    defaultValues
  });
  const serialNumberOptions = (serialNumbers?.devices.map((device) => device.serialNumber) as string[]) || [];
  const operationResultOptions =
    operationResults?.deviceOperationResults.map((operationResult) => operationResult.code) || [];
  const successResultObjects = operationResults?.deviceOperationResults.filter((item) => item.isSuccessful) || [];
  const successResultCodes = successResultObjects.map((item) => item.code);
  const failedResultObjects = operationResults?.deviceOperationResults.filter((item) => !item.isSuccessful) || [];
  const failedResultCodes = failedResultObjects.map((item) => item.code);
  const operationResultOptionsWithInProgress = [IN_PROGRESS_CODE, ...operationResultOptions];
  const annotationOptions = Object.values(AnnotationResult);
  const operationalLifeCycleOptions = Object.values(OperationalLifeCycle);
  const customerNameOptions = customerNames?.customers.map((customer) => customer.company.name) || [];
  const siteNameOptions = siteNames?.sites.map((site) => site.name) || [];
  const programNameOptions = programNames?.programs.map((program) => program.name) || [];
  const activeOptions = Object.values(DeviceDeactivated);

  const annotationDisplayOption = (option: RSAutocompleteValue): JSX.Element => {
    return <RSAutocompleteDefaultMenuOption option={mapAnnotationResultDisplayLabel(option as AnnotationResult)!} />;
  };
  const annotationTagDisplayOption = (option: RSAutocompleteValue) =>
    mapAnnotationResultDisplayLabel(option as AnnotationResult);
  const operationalLifeCycleMenuDisplayOption = (option: RSAutocompleteValue): JSX.Element => {
    return (
      <RSAutocompleteDefaultMenuOption
        option={mapOperationalLifeCycleDisplayLabel(option as OperationalLifeCycleType)!}
      />
    );
  };
  const operationalLifeCycleTagDisplayOption = (option: RSAutocompleteValue) =>
    mapOperationalLifeCycleDisplayLabel(option as OperationalLifeCycleType);

  const resultDisplayTag = (option: RSAutocompleteValue): string => {
    const resultObject = operationResults?.deviceOperationResults.find((item) => item.code === option);
    const readableResult = resultObject?.name;
    return readableResult || t('operationsPage.results.inProgress');
  };

  const periodValues = Object.values(OperationPeriod);
  const periodSelectOptions: RSSelectItemProps[] = periodValues.map((item) => ({
    displayName: mapOperationPeriodDisplayLabel(item),
    menuItemProps: { value: item }
  }));

  const onSubmit: SubmitHandler<OperationsFilters> = (data): void => {
    // Convert the date from the date picker field to the user-selected time zone.
    // Although the date picker field has a `timezone` property, but it does not update when the timezone in the context
    // changes. Therefore we do the timezone conversion here.
    const dataToSubmit: OperationsOverviewSearchParameters = { ...data, dateTime: undefined };
    if (data.period === OperationPeriod.CustomRange) {
      // An validation error will be thrown if data.period === OperationPeriod.CustomRange with no date range
      const dateTimeData = data.dateTime as DateTime<boolean>[];
      // Get the "plain text" date right from the date picker.
      const datesInString = dateTimeData.map((luxonDateTime) => luxonDateTime.toISODate());
      const timezoneForQuery = validateTimezone(userTimezone) ? userTimezone : browserTimezone;
      // Assign the user-selected timezone for the date string
      const startAtFrom = DateTime.fromISO(datesInString[0]!, { zone: timezoneForQuery }).toISO();
      const startAtTo = DateTime.fromISO(datesInString[1]!, { zone: timezoneForQuery })
        .plus({ day: 1 })
        .minus({ millisecond: 1 })
        .toISO();
      dataToSubmit.dateTime = [startAtFrom!, startAtTo!];
    }
    const newSearchParams = {
      ...qs.parse(searchParams.toString()),
      ...dataToSubmit,
      page: '1'
    };
    setSearchParams(new URLSearchParams(qs.stringify(newSearchParams, { arrayFormat: 'brackets' })));
    reset(data);
  };

  const onReset: SubmitHandler<OperationsFilters> = (): void => {
    const searchParamsObject = qs.parse(searchParams.toString());
    const searchParamsObjectNoFilter: OperationsOverviewSearchParameters = {
      ...omit(searchParamsObject, operationsFilterFields),
      period: OperationPeriod.FromStart
    };
    searchParamsObjectNoFilter.page = 1;
    setSearchParams(new URLSearchParams(qs.stringify(searchParamsObjectNoFilter, { arrayFormat: 'brackets' })));
    reset({
      serialNumber: [],
      dateTime: [null, null],
      result: [],
      period: OperationPeriod.FromStart,
      annotation: [],
      operationalLifeCycle: [],
      customer: [],
      site: [],
      program: [],
      deactivated: [],
      remark: ''
    });
  };

  useEffect(() => {
    const searchParameters = qs.parse(searchParams.toString());
    const validUrlFields = filterValidUrlFields<OperationsOverviewSearchParameters>(
      searchParameters,
      operationsOverviewStatesSchema
    );

    const filterParameters = pick(validUrlFields, operationsFilterFields);
    const resetObject: OperationsFilters = {
      serialNumber: filterParameters.serialNumber || [],
      site: filterParameters.site || [],
      operationalLifeCycle: filterParameters.operationalLifeCycle || [],
      customer: filterParameters.customer || [],
      program: filterParameters.program || [],
      deactivated: filterParameters.deactivated || [],
      result: filterParameters.result || [],
      annotation: filterParameters.annotation || [],
      period: filterParameters.period,
      dateTime: filterParameters.dateTime
        ? filterParameters.dateTime.map((stringDateTime) => DateTime.fromISO(stringDateTime))
        : [null, null],
      remark: filterParameters.remark || ''
    };
    if (!isEqual(getValues(), resetObject)) {
      reset(resetObject);
    }
  }, [routerLocation.search]);

  return (
    <aside className="operations-filter-panel" data-testid="operations-filter-panel">
      <form className="operations-filter-panel__form" onSubmit={handleSubmit(onSubmit)}>
        <div className="operations-filter-panel__filters">
          <Controller
            name="serialNumber"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('operationsPage.tableColumns.serialNumber')}
                  data-testid="operations-serial-number-autocomplete"
                  options={serialNumberOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(serialNumberOptions, valueItem))}
                  onBlur={onBlur}
                />
              );
            }}
          />
          <Controller
            name="result"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <div className="operations-filter-panel__autocomplete-result">
                  <RSAutocomplete
                    inputLabel={t('operationsPage.tableColumns.result')}
                    data-testid="operations-result-autocomplete"
                    customMenuOption={getOperationResultDisplayOption(operationResults)}
                    customTagOption={resultDisplayTag}
                    options={operationResultOptionsWithInProgress}
                    onChange={(_event, values) => onChange(values)}
                    value={filter(value, (valueItem) => includes(operationResultOptionsWithInProgress, valueItem))}
                    onBlur={onBlur}
                  />
                  <div className="operations-filter-panel__autocomplete-result-presets">
                    <span>{t('operationsPage.filterPanel.resultPresets.select')}</span>
                    {/*
                      https://dev.azure.com/rocsys/Cake/_boards/board/t/Crew%20-%20Cloud/Backlog%20items/?workitem=13666
                      In principle here we shall use the component <RSActionLink>, however in practice it is a button
                      in a form, meaning that it will take the default behaviour of a form-button: if the user presses
                      "enter", it will consider it as a "click" event and execute automatically for the 1st button of
                      of the form. Therefore, we use <span> instead and borrow the styling from <RSActionLink />.
                    */}
                    <span
                      className="operations-filter-panel__autocomplete-result-preset-option rs-action-link"
                      data-testid="operation-filter-panel-result-preset-all-success"
                      onClick={(event) => {
                        event.preventDefault(); // Not do this will submit the form
                        const currentValue = value || [];
                        const updatedValue = uniq([...currentValue, ...successResultCodes]);
                        setValue('result', updatedValue, { shouldDirty: true });
                      }}
                    >
                      {t('operationsPage.filterPanel.resultPresets.allSuccess')}
                    </span>
                    <span>{t('operationsPage.filterPanel.resultPresets.divider')}</span>
                    <span
                      className="operations-filter-panel__autocomplete-result-preset-option rs-action-link"
                      data-testid="operation-filter-panel-result-preset-all-failed"
                      onClick={(event) => {
                        event.preventDefault(); // Not do this will submit the form
                        const currentValue = value || [];
                        const updatedValue = uniq([...currentValue, ...failedResultCodes]);
                        setValue('result', updatedValue, { shouldDirty: true });
                      }}
                    >
                      {t('operationsPage.filterPanel.resultPresets.allFailed')}
                    </span>
                  </div>
                </div>
              );
            }}
          />
          <Controller
            name="annotation"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('operationsPage.filterPanel.annotation')}
                  data-testid="operations-annotation-autocomplete"
                  customMenuOption={annotationDisplayOption}
                  customTagOption={annotationTagDisplayOption}
                  options={annotationOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(annotationOptions, valueItem))}
                  onBlur={onBlur}
                />
              );
            }}
          />
          <Controller
            name="period"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <RSSelect
                className="operations-filter-panel__period-input"
                inputLabel={t('operationsPage.filterPanel.period')}
                menuItems={periodSelectOptions}
                helperText={errors.period?.message}
                error={Boolean(errors.period)}
                onBlur={onBlur}
                onChange={(event) => {
                  onChange(event.target.value);
                  if (event.target.value !== OperationPeriod.CustomRange) {
                    setValue(
                      'dateTime',
                      calculateTimeRangeFromPeriod(
                        event.target.value as OperationPeriod,
                        userTimezone || browserTimezone
                      )
                    );
                  }
                }}
                data-testid="input-operation-period-select"
                value={value}
              />
            )}
          />
          <Controller
            name="dateTime"
            control={control}
            render={({ field: { onChange, value } }) => {
              const periodValue = watch('period');
              return (
                <OperationsFilterDatePicker
                  onChange={(dateValue) => {
                    onChange(dateValue);
                    setValue('period', OperationPeriod.CustomRange);
                  }}
                  value={
                    periodValue === OperationPeriod.CustomRange
                      ? value
                      : calculateTimeRangeFromPeriod(
                          periodValue || OperationPeriod.Last90Days,
                          userTimezone || browserTimezone
                        )
                  }
                  timezone={userTimezone || browserTimezone}
                  errors={errors}
                />
              );
            }}
          />
          <Controller
            name="operationalLifeCycle"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('operationsPage.tableColumns.operationalLifeCycle')}
                  data-testid="operations-operational-life-cycle-autocomplete"
                  options={operationalLifeCycleOptions}
                  customMenuOption={operationalLifeCycleMenuDisplayOption}
                  customTagOption={operationalLifeCycleTagDisplayOption}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(operationalLifeCycleOptions, valueItem))}
                  onBlur={onBlur}
                />
              );
            }}
          />
          {showCustomer && (
            <Controller
              name="customer"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <RSAutocomplete
                    inputLabel={t('operationsPage.tableColumns.customer')}
                    data-testid="operations-customer-autocomplete"
                    options={customerNameOptions}
                    onChange={(_event, values) => onChange(values)}
                    value={filter(value, (valueItem) => includes(customerNameOptions, valueItem))}
                    onBlur={onBlur}
                  />
                );
              }}
            />
          )}
          <Controller
            name="site"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('operationsPage.tableColumns.site')}
                  data-testid="operations-site-autocomplete"
                  options={siteNameOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(siteNameOptions, valueItem))}
                  onBlur={onBlur}
                />
              );
            }}
          />
          <Controller
            name="program"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('operationsPage.tableColumns.program')}
                  data-testid="operations-program-autocomplete"
                  options={programNameOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(programNameOptions, valueItem))}
                  onBlur={onBlur}
                />
              );
            }}
          />
          <Controller
            name="deactivated"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('operationsPage.tableColumns.deactivated')}
                  data-testid="operations-device-deactivated-autocomplete"
                  options={activeOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(activeOptions, valueItem))}
                  onBlur={onBlur}
                />
              );
            }}
          />
          <RSTextInput
            inputLabel={
              <div className="operations-filter-panel__remark-input-label">
                {t('operationsPage.tableColumns.remark')}
                <RSTooltip title={t('operationsPage.remark.explanation')}>
                  <div className="operations-filter-panel__remark-info">
                    <InfoIcon className="operations-filter-panel__remark-info-icon" />
                  </div>
                </RSTooltip>
              </div>
            }
            data-testid="operations-device-remark-input"
            {...register('remark')}
          />
        </div>
        <FilterPanelButtonsGroup
          handleApply={handleSubmit(onSubmit)}
          handleClearAll={handleSubmit(onReset)}
          isApplyDisabled={!isDirty}
        />
      </form>
    </aside>
  );
};

import { UserDeviceGroupRole, UserDeviceGroupUserRole } from '../../__generated__/graphql';
import i18n from '../../i18n';

export const mapUserDeviceGroupRole = (userRole: UserDeviceGroupRole | UserDeviceGroupUserRole): string => {
  switch (userRole) {
    case UserDeviceGroupRole.DeviceAdministrator:
    case UserDeviceGroupUserRole.DeviceAdministrator:
      return i18n.t('displayDeviceGroupRole.deviceAdministrator');
    case UserDeviceGroupRole.DeviceEngineer:
    case UserDeviceGroupUserRole.DeviceEngineer:
      return i18n.t('displayDeviceGroupRole.deviceEngineer');
    case UserDeviceGroupRole.DeviceObserver:
    case UserDeviceGroupUserRole.DeviceObserver:
      return i18n.t('displayDeviceGroupRole.deviceObserver');
    case UserDeviceGroupRole.DeviceOperator:
    case UserDeviceGroupUserRole.DeviceOperator:
      return i18n.t('displayDeviceGroupRole.deviceOperator');
    default:
      return i18n.t('noData');
  }
};

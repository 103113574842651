import { QueryHookOptions, useQuery } from '@apollo/client';
import { JSX } from 'react';

import { GetCustomerCurrentPerformanceAggregateQuery } from '../../../__generated__/graphql';
import { QUERY_GET_CUSTOMER_CURRENT_PERFORMANCE_AGGREGATE } from '../../../services/queries';
import { calculateACDCycleOperationsResultSuccessRates, execIfBothDefined } from '../../../utilities';
import { PerformanceMetricsRender } from '../../4-features';

interface PerformanceMetricsCustomerAggregateProps {
  queryHookOptions?: QueryHookOptions<GetCustomerCurrentPerformanceAggregateQuery>;
}

export const PerformanceMetricsCustomerAggregate = ({
  queryHookOptions
}: PerformanceMetricsCustomerAggregateProps): JSX.Element => {
  const { loading, data, error } = useQuery(QUERY_GET_CUSTOMER_CURRENT_PERFORMANCE_AGGREGATE, { ...queryHookOptions });

  const counts = data?.customerCurrentPerformanceAggregate.aggregate?.sum || {};

  const values = calculateACDCycleOperationsResultSuccessRates(
    counts?.successLastPeriod ?? null,
    execIfBothDefined(counts.totalLastPeriod, counts.successLastPeriod, (val1, val2) => val1 - val2),
    counts.successPrevPeriod ?? null,
    execIfBothDefined(counts.totalPrevPeriod, counts.successPrevPeriod, (val1, val2) => val1 - val2)
  );

  return (
    <PerformanceMetricsRender
      acdCycleOperationsSuccessRate={values.acdCycleOperationsSuccessRate}
      acdCycleOperationsSuccessRateChange={values.acdCycleOperationsSuccessRateChange}
      acdCycleOperationsCount={values.acdCycleOperationsCount}
      acdCycleOperationsCountChange={values.acdCycleOperationsCountChange}
      loadingTotalAcdCyclesOperationResult={loading}
      errorTotalAcdCyclesOperationResult={Boolean(error)}
    />
  );
};

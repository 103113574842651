import { InputLabel } from '@mui/material';
import { JSX, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { RSSwitch } from '../../5-elements';
import { AutoRefreshContext } from '../../contexts';

export const AutoRefreshSwitch = (): JSX.Element => {
  const { t } = useTranslation();
  const { autoRefresh, setAutoRefresh } = useContext(AutoRefreshContext);

  return (
    <div className="auto-refresh-switch" data-testid="auto-refresh-switch">
      <InputLabel
        shrink={true}
        className="auto-refresh-switch__input-label"
        data-testid="auto-refresh-switch-input-label"
      >
        {t('autoRefreshSwitch.label')}
      </InputLabel>
      <div className="auto-refresh-switch__toggle">
        <RSSwitch
          data-testid="auto-refresh-switch-button"
          falseValueLabel={t('autoRefreshSwitch.off')}
          trueValueLabel={t('autoRefreshSwitch.on')}
          checked={autoRefresh}
          onChange={(event) => setAutoRefresh(event.target.checked)}
        />
      </div>
    </div>
  );
};

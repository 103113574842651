import { gql } from '../../../../__generated__/gql';

export const QUERY_GET_ADMIN_USER_DETAILS = gql(`
  query getAdminUserDetails($userId: uuid!) {
    userByPK(id: $userId) {
      id
      firstName
      lastName
      email
      userType
      createdAt
      updatedAt
      ssoConfiguration {
        id
        enabled
      }
      company {
        id
        name
        companyType
      }
      isSuperUser
      userPermissionLinks {
        id
        userPermission
      }
      userDeviceGroups {
        id
        deviceGroup {
          id
          name
          customer {
            companyId
            company {
              id
              name
            }
          }
          deviceGroupDevicesAggregate {
            aggregate {
              count
            }
          }
          userDeviceGroupsAggregate {
            aggregate {
              count
            }
          }
        }
        userRole
        validUntil
        createdAt
        updatedAt
      }
    }
  }
`);

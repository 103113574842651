import { gql } from '../../../../__generated__/gql';

export const QUERY_GET_ADMIN_COMPANY_DETAILS = gql(`
  query getAdminCompanyDetails($companyId: uuid!) {
    companyByPK(id: $companyId) {
      id
      companyType
      name
      maxDeviceGroupRole
      createdAt
      updatedAt
      customer {
        companyId
        allowImport
        sites {
          id
          name
          timezoneName
          createdAt
          updatedAt
          location {
            id
            formattedAddress
          }
        }
        deviceGroups {
          id
          name
          createdAt
          updatedAt
          serviceProvider {
            companyId
            company {
              id
              name
            }
          }
          deviceGroupDevicesAggregate {
            aggregate {
              count
            }
          }
          userDeviceGroupsAggregate {
            aggregate {
              count
            }
          }
        }
      }
      serviceProvider {
        companyId
        createdAt
        updatedAt
        deviceGroups {
          id
          name
          createdAt
          updatedAt
          customer {
            companyId
            company {
              id
              name
            }
          }
          deviceGroupDevicesAggregate {
            aggregate {
              count
            }
          }
          userDeviceGroupsAggregate {
            aggregate {
              count
            }
          }
        }
      }
      companySettings {
        id
        rocsysApiIpAcceptlist
      }
    }
  }
`);

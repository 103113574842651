import { JSX } from 'react';

import {
  GetAllDeviceOperationsPerformanceQuery,
  GetAllDeviceOperationsPerformanceQueryVariables
} from '../../../../__generated__/graphql';
import { calculateSuccessPercentage, execIfBothDefined } from '../../../../utilities';
import { PerformanceMetricsRender } from '../../../4-features';

interface OperationPerformanceMetricsProps {
  performanceQueryVariables: GetAllDeviceOperationsPerformanceQueryVariables;
  loadingPerformance: boolean;
  errorPerformance: boolean;
  data?: GetAllDeviceOperationsPerformanceQuery;
}

export const OperationPerformanceMetrics = ({
  loadingPerformance,
  errorPerformance,
  data
}: OperationPerformanceMetricsProps): JSX.Element => {
  const acdCycleOperationsSuccess = data?.totalACDCycleOperationsSuccess.aggregate?.count || 0;
  const acdCycleOperationsFailed = data?.totalACDCycleOperationsFailed.aggregate?.count || 0;
  const acdCycleOperationsSuccessRate = calculateSuccessPercentage(acdCycleOperationsFailed, acdCycleOperationsSuccess);
  const acdCycleOperationsCount = execIfBothDefined(
    acdCycleOperationsSuccess,
    acdCycleOperationsFailed,
    (val1, val2) => val1 + val2
  );

  return (
    <PerformanceMetricsRender
      acdCycleOperationsSuccessRate={acdCycleOperationsSuccessRate}
      acdCycleOperationsCount={acdCycleOperationsCount}
      loadingTotalAcdCyclesOperationResult={loadingPerformance}
      errorTotalAcdCyclesOperationResult={errorPerformance}
      isRawPerformance={true}
    />
  );
};

import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { GetAdminDeviceDetailsQuery } from '../../../../__generated__/graphql';
import { appConfig } from '../../../../configs';
import { contextualizeBoolean, getNonNullishDisplayValue } from '../../../../utilities';
import { AdminDetailsPanelTitle } from '../../../4-features';
import { InformationBlock } from '../../../5-elements';
import { useFormatTimezone } from '../../../hooks';

interface DeviceDetailsAdminPanelProps {
  // This panel will only be shown if the device details are successfully loaded
  deviceDetails: NonNullable<GetAdminDeviceDetailsQuery['deviceByPK']>;
}

export const DeviceDetailsAdminPanel = ({ deviceDetails }: DeviceDetailsAdminPanelProps): JSX.Element => {
  const { t } = useTranslation();
  const { formatWithDefaultTimezone } = useFormatTimezone();

  return (
    <aside className="device-details-admin-panel" data-testid="device-details-admin-panel">
      <AdminDetailsPanelTitle
        objectType={t('deviceAdminDetailsPage.panel.type')}
        objectName={getNonNullishDisplayValue(deviceDetails.serialNumber)}
        goBackUrl={`${appConfig.basePath}/admin/devices`}
        editButtonProps={{ disabled: true }}
      />
      <div className="device-details-admin-panel__contents">
        <InformationBlock
          label={t('deviceAdminDetailsPage.panel.customer')}
          data-testid="device-details-admin-panel-customer"
          value={getNonNullishDisplayValue(deviceDetails.site.customer.company?.name)}
        />
        <InformationBlock
          label={t('deviceAdminDetailsPage.panel.site')}
          data-testid="device-details-admin-panel-site"
          value={getNonNullishDisplayValue(deviceDetails.site.name)}
        />
        <InformationBlock
          label={t('deviceAdminDetailsPage.panel.program')}
          data-testid="device-details-admin-panel-program"
          value={getNonNullishDisplayValue(deviceDetails.program?.name)}
        />
        <InformationBlock
          label={t('deviceAdminDetailsPage.panel.deviceType')}
          data-testid="device-details-admin-panel-device-type"
          value={getNonNullishDisplayValue(deviceDetails.deviceType?.name)}
        />
        <InformationBlock
          label={t('deviceAdminDetailsPage.panel.connectorHolderType')}
          data-testid="device-details-admin-panel-connector-holder-type"
          value={getNonNullishDisplayValue(deviceDetails.connectorHolderType?.name)}
        />
        <InformationBlock
          label={t('deviceAdminDetailsPage.panel.latitude')}
          data-testid="device-details-admin-panel-latitude"
          value={getNonNullishDisplayValue(deviceDetails.location?.latitude)}
        />
        <InformationBlock
          label={t('deviceAdminDetailsPage.panel.longitude')}
          data-testid="device-details-admin-panel-longitude"
          value={getNonNullishDisplayValue(deviceDetails.location?.longitude)}
        />
        <InformationBlock
          label={t('deviceAdminDetailsPage.panel.description')}
          data-testid="device-details-admin-panel-description"
          value={getNonNullishDisplayValue(deviceDetails.description)}
        />
        <InformationBlock
          label={t('deviceAdminDetailsPage.panel.deActivated')}
          data-testid="device-details-admin-panel-deactivated"
          value={contextualizeBoolean(
            t('deviceAdminDetailsPage.panel.deActivatedTrue'),
            t('deviceAdminDetailsPage.panel.deActivatedFalse'),
            deviceDetails.deactivated
          )}
        />
        <InformationBlock
          label={t('deviceAdminDetailsPage.panel.createdAt')}
          data-testid="device-details-admin-panel-created-at"
          value={formatWithDefaultTimezone(deviceDetails.createdAt)}
        />
        <InformationBlock
          label={t('deviceAdminDetailsPage.panel.updatedAt')}
          data-testid="device-details-admin-panel-updated-at"
          value={formatWithDefaultTimezone(deviceDetails.updatedAt)}
        />
      </div>
    </aside>
  );
};
